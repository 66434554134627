@mixin common-button {
  .commBtnWrap {
    button {
      width: max-content;
      gap: 1rem;
      padding-right: 0.5rem;
      height: 5rem;
      border-radius: 5rem;
      background-size: 100% 100%;
      background-image: linear-gradient(
        90deg,
        rgba(45, 76, 195, 1) 45%,
        rgba(154, 155, 237, 1) 100%
      );
      color: #fff;
      border: 0;
      font-size: 1.6rem;
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-left: 1.5rem;
      position: relative;
      @include x-767-tablet {
        font-size: 1.4rem;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 5rem;
        background-color: #fff;
        overflow: hidden;
        will-change: transform;
        .oldArrow {
          transform: translateX(-135%);
          transform-style: preserve-3d;
          transition: transform 0.2s ease;
          position: relative;
        }
        .newArrow {
          transform: translateX(-55%);
        }
      }

      &:hover {
        background-position: 0% 100%;
        transition: all 0.2s ease-in-out;
        span {
          .oldArrow {
            transform: translateX(45%);
          }
          .newArrow {
            transform: translateX(140%);
          }
        }
      }
    }
  }
}

@mixin mainTitle {
  font-size: 3rem;
}

@mixin paraTypo {
  font-size: 1.8rem;
  text-decoration: none;
  color:#6d6d6e;
}
@mixin paraTypo-hover {
  &:hover {
    color:blue;
  }
}

@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background: #2d4cc3;
    border-radius: 16px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25);
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      90deg,
      #87c9ff,
      #87c9ff 1px,
      #87c9ff 0,
      #87c9ff
    );
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25);
  }
  &::-webkit-scrollbar-corner {
    background: #87c9ff;
  }
}

@mixin textGradient {
  .mainText {
    font-size: 9.5rem;
    // font-family: "AeonikTRIAL-Bold";
    text-align: center;
    @include d-1366-desktop {
      font-size: 5.5rem;
    }
    @include x-767-tablet {
      font-size: 3.5rem;
    }
    .gradientText {
      position: relative;
      width: -webkit-max-content;
      width: max-content;
      background: #6d24ba;
      background: linear-gradient(
        to right,
        #6d24ba 0%,
        #96e0f3 25%,
        #a2b8f7 32%,
        #a65ff8 49%,
        #5949e0 77%,
        #313ed3 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "AeonikTRIAL-Bold";
    }
  }
}

@mixin productSliderWrap{
  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image-container {
      display:contents;
      position: relative;
      width: 50rem;
      height: 30rem;
      text-align: center;
      @include d-desktop {
        width: 35rem;
      }
      @include x-767-tablet {
        width: 100%;
      }
      .main-image {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease-in-out;
      }
    }

    .thumbnail-container {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      gap: 2rem;
      flex-wrap: wrap;
      width: 50rem;
      padding: 1rem;
      border: 0.1rem solid #dddddd;
      border-radius: 0.3rem;

      @include x-767-tablet {
        width: 100%;
        // gap: 0rem;
      }
      @include mobile{

      }
      .thumbNailBox {
        width: 9rem;
        height: 5rem;
        margin: 0 0.5rem;
        cursor: pointer;
      }
      .thumbnail {
        transition: opacity 0.3s;
        width: 100%;
        height: 100%;
        &.active {
          opacity: 0.5;
          & ~ .main-image {
            transform: scale(0.8);
          }
        }
      }
    }
  }
}