@media (max-width: 767px) {
  .modal-dialog-centered {
    margin: 0;
  }
}

@media (max-width: 834px) {
  .modal-dialog {
    max-width: 98%;
  }
}
@media (max-width: 767px) {
  .modal-dialog {
    max-width: 100%;
  }
}

.modal-content .btn-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.6rem;
  color: #000;
  opacity: 1;
  z-index: 1;
}
.modal-content .btn-close:focus {
  outline: none;
  box-shadow: none;
}

.product-details {
  margin-bottom: 4rem;
  position: relative;
}
.product-details .section {
  display: flex;
  flex-wrap: wrap;
}
.product-details .section.step1 {
  padding: 1rem 0;
}
.product-details .section.step1 .checkoutDataWrap {
  display: none;
}
.product-details .section.step2 {
  padding: 3rem 0 0;
}
.product-details .section.step3 {
  padding: 14rem 0rem 1rem 0rem;
  flex-direction: column;
  text-align: center;
}
.product-details .section.step3 .commBtnWrap button {
  margin-bottom: 2rem;
}
.product-details .padLeft {
  padding: 0 1rem;
  max-height: 55rem;
}
.product-details .padLeft::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.product-details .padLeft::-webkit-scrollbar-thumb {
  background: #2d4cc3;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
.product-details .padLeft::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #87c9ff, #87c9ff 1px, #87c9ff 0, #87c9ff);
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
.product-details .padLeft::-webkit-scrollbar-corner {
  background: #87c9ff;
}
@media (max-width: 834px) {
  .product-details .padLeft {
    padding-bottom: 10rem;
  }
}
@media (max-width: 767px) {
  .product-details .padLeft {
    max-height: 100%;
    overflow: unset;
    padding-bottom: 0;
  }
}
.product-details .product-image-slider {
  border-radius: 10px;
  background-color: #D2EBFF;
  padding-bottom: 21px;
}
@media (max-width: 767px) {
  .product-details .product-image-slider {
    padding-bottom: 5rem;
  }
}
.product-details .product-image-slider .main-image {
  display: flex;
  border-radius: 1rem;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;
}
@media (max-width: 834px) {
  .product-details .product-image-slider .main-image {
    height: 30.2rem;
  }
}
@media (max-width: 767px) {
  .product-details .product-image-slider .main-image {
    max-width: calc(100% - 0rem);
    height: 100%;
  }
}
.product-details .product-image-slider .main-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 65px 42px 42px 42px;
}
.product-details .product-image-slider .thumbs-container .swiper .swiper-wrapper .swiper-slide {
  width: 8.3rem;
  height: 7.6em;
}
.product-details .product-image-slider .thumbs-container .swiper .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
}
.product-details .product-info {
  margin-bottom: 2rem;
}
.product-details .product-info h1 {
  font-size: 2.6rem;
}
.product-details .product-info p {
  font-size: 1.6rem;
}
.product-details .bundle-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.product-details .bundle-selector label {
  font-size: 1.8rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
}
@media (max-width: 480px) {
  .product-details .bundle-selector label {
    padding-left: 4px;
  }
}
.product-details .bundle-selector select {
  height: 3.5rem;
  width: 60%;
  border: 0.2rem solid #919191;
  border-radius: 5rem;
  padding: 0 1.5rem;
  font-size: 1.4rem;
  color: #1d1d1f;
  font-family: "AeonikTRIAL-Regular";
  background-image: linear-gradient(45deg, transparent 50%, #1d1d1f 60%), linear-gradient(135deg, #1d1d1f 40%, transparent 50%) !important;
  background-position: calc(100% - 22px) 14px, calc(100% - 15px) 14px, 100% 0;
  background-size: 7px 7px, 7px 7px;
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
@media (max-width: 480px) {
  .product-details .bundle-selector select {
    width: 100%;
  }
}
.product-details .bundle-selector select:focus {
  outline: none;
  box-shadow: none;
}
.product-details .subcourses {
  margin-bottom: 2rem;
}
.product-details .subcourses ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 2.4rem;
       column-gap: 2.4rem;
  row-gap: 1.5rem;
}
.product-details .subcourses ul li {
  font-size: 1.6rem;
  color: #5d5d5d;
}
.product-details .subcourses ul li::before {
  content: url(../../assets/images/bookIcon.svg);
  padding-right: 0.5rem;
}
.product-details .colorQtWraps {
  display: flex;
  gap: 4rem;
}
.product-details .colorQtWraps .color-selector .color-select {
  font-size: 1.6rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
}
.product-details .colorQtWraps .color-selector .radioWrap {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}
.product-details .colorQtWraps .color-selector .radioWrap input[type=radio]:checked::before {
  content: "";
  display: block;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.product-details .colorQtWraps .color-selector .radioWrap label {
  font-size: 1.6rem;
  font-family: "AeonikTRIAL-Regular";
  color: #919191;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.product-details .colorQtWraps .qtSelectWrap {
  display: flex;
  flex-direction: column;
}
.product-details .colorQtWraps .qtSelectWrap label {
  font-size: 1.6rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
}
.product-details .colorQtWraps .qtSelectWrap label:checked {
  font-weight: bold;
}
.product-details .colorQtWraps .qtSelectWrap .qttBox {
  height: 2.8rem;
  width: 5rem;
  border: 0.2rem solid #919191;
  border-radius: 5rem;
  padding: 0 1.5rem;
  font-size: 1.8rem;
  font-family: "AeonikTRIAL-Regular";
  color: #1d1d1f;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}
.product-details .colorQtWraps .qtSelectWrap p {
  font-size: 1.6rem;
  font-family: "AeonikTRIAL-Bold";
  color: #545454;
  margin-top: 5px;
}
.product-details .produtcMainPrices {
  margin-top: 3rem;
}
.product-details .produtcMainPrices h2 {
  font-size: 4rem;
  font-family: "AeonikTRIAL-Bold";
  color: #2d4cc3;
  margin-bottom: 0;
}
.product-details .produtcMainPrices h2 del {
  font-size: 3.2rem;
  font-family: "AeonikTRIAL-Regular";
  color: #919191;
}
.product-details .defaultBox {
  margin-top: 3rem;
}
.product-details .defaultBox label {
  font-size: 1.8rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
}
.product-details .defaultBox p {
  font-size: 1.6rem;
}
.product-details .defaultBox ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 2.4rem;
       column-gap: 2.4rem;
  row-gap: 1.5rem;
}
.product-details .defaultBox ul li {
  font-size: 1.6rem;
  color: #5d5d5d;
}
.product-details .defaultBox ul li::before {
  content: url(../../assets/images/bookIcon.svg);
  padding-right: 0.5rem;
}
.product-details .order-summary {
  background-color: #fff;
  box-shadow: 0 0 1rem 0 rgba(188, 188, 188, 0.7411764706);
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  height: 9.3rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5rem;
}
@media (max-width: 480px) {
  .product-details .order-summary {
    border: 1px solid gray;
    border-radius: 1rem;
    padding: 18px;
  }
}
@media (max-width: 767px) {
  .product-details .order-summary {
    flex-direction: column;
    align-items: flex-start;
    box-shadow: unset;
    position: relative;
    height: 100%;
    gap: 1rem;
  }
}
.product-details .order-summary .leftBoxWp {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 480px) {
  .product-details .order-summary .leftBoxWp {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .product-details .order-summary .leftBoxWp {
    width: 100%;
    justify-content: flex-start;
  }
}
.product-details .order-summary .leftBoxWp .finalProductImgWRap {
  height: 7rem;
  width: 7rem;
  overflow: hidden;
  padding: 0.5rem;
}
.product-details .order-summary .leftBoxWp .finalProductImgWRap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 480px) {
  .product-details .order-summary .leftBoxWp .finalProductImgWRap img {
    -o-object-fit: contain;
       object-fit: contain;
  }
}
.product-details .order-summary .leftBoxWp .nameColrWrap h2 {
  font-size: 1.8rem;
}
.product-details .order-summary .leftBoxWp .nameColrWrap p {
  font-size: 1.8rem;
  font-family: "AeonikTRIAL-Regular";
  color: #1d1d1f;
  text-transform: capitalize;
}
.product-details .order-summary .leftBoxWp .nameColrWrap p span {
  color: #919191;
}
.product-details .order-summary .midBoxWp h2 {
  font-size: 1.8rem;
}
.product-details .order-summary .midBoxWp p {
  font-size: 1.8rem;
  font-family: "AeonikTRIAL-Regular";
  color: #919191;
  text-transform: capitalize;
}
.product-details .order-summary .rightBoxWp {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 767px) {
  .product-details .order-summary .rightBoxWp {
    width: 100%;
    justify-content: space-between;
  }
}
.product-details .order-summary .rightBoxWp .pricing {
  font-size: 3rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
  margin-bottom: 0;
}
.product-details .order-summary .rightBoxWp .commBtnWrap button {
  margin-top: 0;
  padding: 10px 5px 10px 15px;
}
.product-details .form-container {
  background-color: #e2e2ff;
  padding: 2rem;
  border-radius: 0.8rem;
}
.product-details .form-container h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3rem;
}
.product-details .form-container .form-group {
  margin-bottom: 2rem;
}
.product-details .form-container .form-group .form-label {
  font-size: 1.6rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
  display: block;
  margin-bottom: 0.8rem;
}
.product-details .form-container .form-group .form-control {
  height: 4.5rem;
  padding: 0 1.5rem;
  font-size: 1.6rem;
  color: #181818;
  border: 0;
}
.product-details .form-container .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #919191;
}
.product-details .form-container .form-group textarea {
  padding-top: 1rem !important;
  height: 6rem !important;
}
.product-details .form-container .form-group .error {
  font-size: 1.6rem;
  color: red;
}
.product-details .form-container .commBtnWrap {
  margin-top: 3rem;
}
.product-details .form-container .commBtnWrap button {
  width: auto;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .product-details .form-container .commBtnWrap button {
    width: 61%;
  }
}
.product-details .form-container .commBtnWrap button span {
  transform: none !important;
}
.product-details .payment-methods img {
  width: 100%;
}

.current-price {
  font-weight: bold;
  color: green;
}

.discounted-price {
  text-decoration: line-through;
  color: red;
}

.order-summary button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.product-image-slider {
  position: relative;
}

.main-image {
  position: relative;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2d4cc3;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.prev-button i,
.next-button i {
  font-size: 1.8rem;
  color: #fff;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.thumbs-container {
  display: flex;
  align-items: center;
}

.prev-thumb-button,
.next-thumb-button {
  background-color: #2d4cc3;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.prev-thumb-button i,
.next-thumb-button i {
  font-size: 1.2rem;
  color: #fff;
}

.thumbs-swiper {
  flex-grow: 1;
}

.thumbs-swiper .swiper-slide {
  cursor: pointer;
  opacity: 0.5;
}

.thumbs-swiper .swiper-slide.active {
  opacity: 1;
}

.Toastify__toast-container {
  font-size: 1.6rem;
}

.order_affiliate {
  height: 3.5rem;
  width: 79%;
  border: 0.2rem solid #919191;
  border-radius: 5rem;
  padding: 0 1.5rem;
  font-size: 1.4rem;
  color: #1d1d1f;
  outline: none;
  font-family: "AeonikTRIAL-Regular";
}
@media (max-width: 480px) {
  .order_affiliate {
    width: 70%;
  }
}

.paymentsuccessfull {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: rgba(0, 0, 0, 0.5);
}

.paymentsuccessfull .modal-content {
  text-align: center;
}

.paymentsuccessfull .modal-content p {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.product-image-slider14XOLED {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #FFF;
  font-weight: 700;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 30px;
  border-radius: 15px;
  font-size: 14px;
  padding: 20px 0px 20px 0px;
  letter-spacing: 0.5px;
  background: rgba(45, 75, 195, 0.89);
  align-items: center;
}/*# sourceMappingURL=modal.css.map */