@import "./mixin";
@import "./media";

@font-face {
  font-family: "AeonikTRIAL-Bold";
  src: url(../fonts/AeonikTRIAL-Bold.otf);
}

@font-face {
  font-family: "AeonikTRIAL-Regular";
  src: url(../fonts/AeonikTRIAL-Regular.otf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.33%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  @include scrollBar;
}

body {
  @include scrollBar;
}

ul {
  li {
    list-style: none;
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
}

button {
  font-family: "AeonikTRIAL-Bold";
}

p,
span,
strong,
b,
a,
li,
input {
  font-family: "AeonikTRIAL-Regular";
  color: #5d5d5d;
}

@include common-button;

// Header Section Start
#header {
  position: fixed;
  top: 5%;
  left: 50%;
  width: 86%;
  display: flex;
  transition: top 0.8s;
  height: 6rem;
  background: #fff;
  box-shadow: 0 0px 10px #54545426;
  z-index: 11;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  padding: 0 0rem 0px 1rem;

  .navbar {
    .navbar-brand {

      // border-right: 0.1rem solid #000;
      // padding-right: 1rem;
      @include x-767-tablet {
        border-right: 0;
        padding-right: 0rem;

        img {
          width: 80%;
        }
      }
    }

    #menuToggle {
      width: 5rem;
      height: 5rem;
      border-radius: 5rem;
      background: linear-gradient(90deg,
          rgb(45, 76, 195) 45%,
          rgb(154, 155, 237) 100%);

      &.navbar-toggler {
        border: 0;

        @include md-tablet {
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: 14px;
        right: 6px;
        cursor: pointer;
        opacity: 0;
        /* hide this */
        z-index: 2;
        /* and place it over the hamburger */
        -webkit-touch-callout: none;

        &:checked {
          ~span {
            opacity: 1;
            transform: rotate(45deg) translate(-3px, -4px);
            background: #fff;

            &:nth-last-child(3) {
              opacity: 1;
              transform: rotate(-46deg) translate(-6px, 2px);
              background: #fff;
            }

            &:nth-last-child(2) {
              opacity: 0;
              transform: rotate(0deg) scale(0.2, 0.2);
            }
          }
        }
      }

      span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #fff;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 22px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:nth-child(3) {
          width: 20px;
        }

        &:first-child {
          transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
          margin: 0 0 5px 13px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .navbar-collapse {
      @include md-tablet {
        background: #ffff;
        box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
        border-radius: 0.6rem;
        display: block;
        height: auto;
        right: -150%;
        overflow-y: auto;
        padding: 2rem 1.5rem;
        position: fixed;
        top: 100%;
        transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
        z-index: 99;
        width: 100%;

        &.show {
          right: 0;
          transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
        }
      }

      .navbar-nav {
        align-items: center;
        column-gap: 2rem;
        margin-right: 1rem;
      }
    }

    .navbar-nav {
      align-items: center;
      gap: 1rem;

      &.extraLinks {
        @include md-tablet {
          display: none;
        }
      }
    }

    .nav-item {
      &.rightBorder {
        border-right: 0.1rem solid #000;
        padding-right: 1rem;

        @include md-tablet {
          border: 0;
          padding: 0;
        }
      }

      .commBtnWrap {
        button {
          margin-top: 0;
          // width: 11.8rem;
        }
      }

      .nav-link {
        @include paraTypo;
        color: #919191;

        &.active {
          font-family: "AeonikTRIAL-Bold";
          color: #000;
        }
      }
    }
  }
}

// Header Section End

// Banner Section Start
#bannerSection {
  // background: radial-gradient(
  //   at top,
  //   #fff 27%,
  //   #f4f6fa 57%,
  //   rgba(226, 234, 247, 0.65) 100%
  // );

  // background-image: url(../images/noisy-gradients.png);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background: radial-gradient(at 50%, #fff 35%, #d9e6ff 57%, rgba(226, 234, 247, .65) 100%);
  background: radial-gradient(at 50% 100%,
      #fff 25%,
      #d9e6ff 100%,
      rgba(226, 234, 247, 0.65) 100%);
  width: 100%;
  height: 100%;
  padding: 9% 0 0;

  @include d-desktop {
    padding: 18% 0 0;
  }

  @include md-tablet {
    padding: 20% 0 0;
  }

  @include x-767-tablet {
    padding: 35% 0 0;
  }

  .container {
    height: 100%;
  }

  .bannerContentRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .bannerTextWrap {
    padding-bottom: 4rem;
    @include textGradient;

    .mainPara {
      width: 54%;
      margin: 0 auto;
      text-align: center;

      @include paraTypo;

      @include x-767-tablet {
        width: 80%;
        font-size: 1.6rem;
      }

      @include mobile {
        width: 67%;
      }
    }

    .commBtnWrap {
      display: flex;
      justify-content: center;
    }
  }

  .centerBoxWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include x-767-tablet {
      display: none;
    }

    @include mobile {
      display: block;
    }

    .priceTag {

      .priceTagImg {
        position: absolute;
        top: -14%;
        left: 27%;
        z-index: 1;

        @include mobile {
          width: 100px;
          left: 10px !important;
          top: -40px;
        }

        @include d-1366-desktop {
          left: 24%;
          z-index: 1;
        }

        @include d-desktop {
          left: 19%;
        }

        @include md-tablet {
          left: 9%;
        }
      }

      .priceAmt {
        position: absolute;
        top: -7.5%;
        left: 29%;
        transform: rotate(25deg);
        font-size: 1.6rem;
        font-family: "AeonikTRIAL-Bold";
        color: #fff;

        @include d-1366-desktop {
          left: 26.5%;
        }

        @include d-desktop {
          left: 21.5%;
        }

        @include md-tablet {
          left: 13%;
        }
      }
    }

  }

  .mainLaptop {
    position: relative;

    .overlapText {
      display: none;
      position: absolute;
      top: 50%;
      left: 80%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 3rem;
      font-family: "AeonikTRIAL-Bold";
      width: 100%;


      @include md-tablet {
        top: 50%;
        left: 50%;
        width: max-content;
      }
    }

    a {
      display: block;
      cursor: pointer;

      span {
        position: absolute;
        left: 49%;
        top: 128%;
        transform: translate(-50%, -50%);

        @include mobile {
          left: 178px;
          top: 289px;
        }

        @include xssm-mobile {
          top: 260px;
        }

        @include xss-mobile {
          left: 151px;
          top: 223px;
        }
      }
    }
  }

  .laptopBox {
    margin-top: -0.3rem;
  }

  .absBox {
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 1rem 2rem;
    border-radius: 0.6rem;
    box-shadow: 0 0px 10px #54545426;
    position: absolute;
    width: 30.4rem;

    &.leftbox {
      right: 0;
      top: 0;

      @include d-1366-desktop {
        top: 40px;
      }

      @include md-tablet {
        display: none;
      }
    }

    &.rightbox {
      left: 0;
      bottom: 40%;

      @include d-1366-desktop {
        bottom: 52%;
      }

      @include md-tablet {
        display: none;
      }
    }

    .boxTitle {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .title {
        font-size: 1.8rem;
        font-family: "AeonikTRIAL-Bold";
        color: #000;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 1.4rem;
      font-family: "AeonikTRIAL-Regular";
    }
  }

  .svgLine {
    pointer-events: none;

    @include md-tablet {
      display: none;
    }
  }
}

.svgLine {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  top: 25%;
  pointer-events: none;

  &.leftArrow {
    left: 4rem;
    width: 36%;

    @include d-1366-desktop {
      top: 15%;
      left: 1rem;
    }
  }

  &.rightArrow {
    @include d-1366-desktop {
      top: 30%;
    }
  }

  .st0 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: draw1 2s linear forwards;
    animation: draw1 2s linear forwards;
  }
}

// Banner Section
@keyframes draw1 {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

// Clients Section Start
#clients {
  @include md-tablet {
    padding: 0rem 0 3rem;
    display: none;
  }

  .client-logo-listing {
    background-color: #f1f1f1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;

    // .brands-marquee {
    //   padding: 25px 0;
    // }
    .js-marquee-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      animation: 25s linear 0s infinite normal none running marqueeAnimation-3709221;
      margin-left: 0px;
      width: 100%;
      transform: translateX(0);

      &:hover {
        animation: 25s linear 0s infinite normal none paused marqueeAnimation-3709221 !important;
      }
    }

    ul {
      display: flex;
      align-items: center;
      height: 100%;

      li {
        padding: 10px 25px 0 25px;
        height: 90px;
        display: block;
      }
    }
  }
}

.svg-sprite {
  background-image: url(../images/css-sprite.png);
  background-repeat: no-repeat;
  display: block;
  height: 100px;

  &.logo-brand-black-oracle {
    background-position: -23px 5px;
    width: 521px;
  }

  &.logo-cambridge-v3 {
    background-position: -522px 5px;
    width: 269px;
  }

  &.abdul-latif-jameel-logo {
    background-position: -785px 5px;
    width: 387px;
  }

  &.abdul-latif-jameel-logo-001 {
    background-position: -1155px 5px;
    width: 238px;
  }

  &.logo-brand-black-mtn {
    background-position: -1396px 5px;
    width: 180px;
  }

  &.logo-brand-black-sanchobbdo {
    background-position: -1573px 5px;
    width: 216px;
  }

  &.logo-brand-black-gulfstream {
    background-position: -1795px 5px;
    width: 205px;
  }

  &.logo-brand-black-daman {
    background-position: -1984px 5px;
    width: 171px;
  }

  &.logo-brand-black-astrazeneca {
    background-position: -2150px 5px;
    width: 150px;
  }
}

@keyframes marqueeAnimation-3709221 {
  100% {
    transform: translateX(-100%);
  }
}

// Clients Section End

// Product Feature Section Start
#productFeature {
  padding: 10rem 0;

  .col-lg-6 {
    position: relative;
  }

  @include x-767-tablet {
    padding: 4rem 0;
  }

  .leftBox {
    @include md-tablet {
      text-align: center;
    }

    h2 {
      @include mainTitle;
      margin-bottom: 1rem;

      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }

    p {
      @include paraTypo;
      margin-bottom: 0;

      @include md-tablet {
        width: 80%;
        margin: 0 auto;
        font-size: 1.6rem;
      }
    }

    .commBtnWrap {
      @include md-tablet {
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;
      }

      @include x-767-tablet {
        margin-bottom: 8rem;
      }
    }
  }

  @include productSliderWrap;
}

.color-container {
  position: absolute;
  right: -7.5rem;
  top: 0;
  display: flex;
  row-gap: 0.5rem;
  width: 15%;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1rem;

  @include x-767-tablet {
    right: 0px;
    top: -35px;
    width: 40%;
    justify-content: flex-end;
  }

  .colorBox {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }

  .color-black {
    background-color: #2f2f2f;
  }

  .color-gray {
    background-color: rgb(148, 148, 148);
  }

  .color-red {
    background-color: rgb(255, 31, 31);
  }

  .color-green {
    background-color: rgb(136, 255, 31);
  }
}

// Product Feature Section End

// Solaris Book Grid Section Start
#solarisGridSection {
  padding: 10rem 0;
  background-image: url(../images/gide-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

  @include x-767-tablet {
    padding: 3rem 1rem;
  }

  .gridWrap {
    margin-bottom: 4rem;

    h2 {
      @include mainTitle;
      margin-bottom: 1rem;
      text-align: center;
      color: #fff;

      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }

    p {
      @include paraTypo;
      margin-bottom: 0;
      text-align: center;
      color: #d9d9d9;
      width: 45%;
      margin: 0 auto;

      @include md-tablet {
        width: 80%;
        font-size: 1.6rem;
      }
    }
  }

  .bookGrid {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    .grid {
      height: 100%;
      width: 100%;
      border-radius: 0.8rem;
      overflow: hidden;
      position: relative;

      .floatBox {
        position: absolute;

        .roundBox {
          background-color: #fff;
          width: 18rem;
          height: 4.5rem;
          border-radius: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;

          @include x-767-tablet {
            height: 3.5rem;
          }

          span {
            font-family: "AeonikTRIAL-Bold";
            font-size: 1.8rem;
            color: #313234;

            @include x-767-tablet {
              font-size: 1.4rem;
            }
          }
        }

        p {
          font-family: "AeonikTRIAL-Regular";
          font-size: 1.4rem;
          color: #fff;
        }

        &.float1 {
          width: 60%;
          left: 5%;
          bottom: 10%;

          @include x-767-tablet {
            bottom: 0%;
          }
        }

        &.float2 {
          width: 40%;
          right: 5%;
          bottom: 10%;
          display: flex;
          flex-direction: column;

          @include x-767-tablet {
            width: 100%;
            bottom: 0;
            left: 52%;
            transform: translateX(-50%);
          }

          .roundBox {
            width: 25rem;
          }
        }
      }

      .flotTitle {
        position: absolute;

        span {
          font-family: "AeonikTRIAL-Bold";
          font-size: 3rem;
          color: #313234;

          @include d-desktop {
            font-size: 2.4rem;
          }

          @include md-tablet {
            font-size: 5rem;
          }

          @include x-767-tablet {
            font-size: 3rem;
          }
        }

        &.title1 {
          top: 10%;
          left: 5%;
        }

        &.title2 {
          right: 5%;
          top: 25%;
        }

        &.title3 {
          left: 5%;
          top: 40%;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .btmBox {
      position: absolute;

      .lightTxt {
        font-family: "AeonikTRIAL-Bold";
        color: #b3b3b3;
        font-size: 1.8rem;
        margin-bottom: 0;
      }

      .darkTxt {
        font-family: "AeonikTRIAL-Bold";
        color: #313234;
        font-size: 1.8rem;
        margin-bottom: 0;
      }

      &.boxed1 {
        bottom: 5%;
        left: 5%;
      }

      &.boxed2 {
        bottom: 5%;
        right: 5%;
      }

      &.boxed3 {
        top: 5%;
        right: 5%;
      }
    }

    .grid1 {
      grid-area: 1 / 1 / 2 / 2;

      @include md-tablet {
        grid-area: 1 / 1 / 2 / 2;
      }
    }

    .grid2 {
      grid-area: 1 / 2 / 2 / 4;

      @include md-tablet {
        grid-area: 2 / 1 / 3 / 2;
      }

      @include x-767-tablet {
        height: 15rem;
      }
    }

    .grid3 {
      grid-area: 2 / 1 / 3 / 2;

      @include md-tablet {
        grid-area: 3 / 1 / 4 / 2;
      }

      .svgLine {
        height: auto;
        width: 70%;
        top: 53%;
        stroke-width: 0.3rem;
        left: 20%;
        pointer-events: none;
      }
    }

    .grid4 {
      grid-area: 2 / 2 / 3 / 3;

      @include md-tablet {
        grid-area: 4 / 1 / 5 / 2;
      }

      .svgLine {
        height: auto;
        top: 55%;
        width: 85%;
        left: 15%;
        stroke-width: 0.3rem;
        pointer-events: none;
      }
    }

    .grid5 {
      grid-area: 2 / 3 / 3 / 4;

      @include md-tablet {
        grid-area: 5 / 1 / 6 / 2;
      }

      .svgLine {
        height: auto;
        top: 54%;
        width: 100%;
        left: -4%;
        stroke-width: 0.3rem;
        pointer-events: none;
      }
    }
  }
}

// Solaris Book Grid Section End

// Feature Slider Section Start
#featureSliderSection {
  padding: 10rem 0;

  @include x-767-tablet {
    padding: 3rem 1rem;
  }

  .sectionTitleWrap {
    h2 {
      @include mainTitle;
      margin-bottom: 1rem;
      text-align: center;

      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }

    p {
      @include paraTypo;
      margin-bottom: 0;
      text-align: center;
      width: 45%;
      margin: 0 auto;

      @include md-tablet {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }

  .slider {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    flex: 1 0 100%;
    min-width: 100%;
    cursor: grab;
  }

  .dots {
    text-align: center;
    margin-top: 5rem;
    padding: 1rem 0;

    @include x-767-tablet {
      margin-top: 0;
    }
  }

  .dot {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #c5c6d9;
    border: 0.1rem solid #a9a9a9;

    cursor: pointer;
  }

  .dot.active {
    background-color: #2d4cc3;
    box-shadow: 0px 0.4rem 0.8rem -0.2rem #202020;
    border: 0;
  }

  .parent {
    display: grid;
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
    padding-top: 8rem;
    padding-bottom: 3rem;
  }

  .box {
    height: 20rem;
    width: 100%;

    @include md-tablet {
      height: 100%;
    }

    .contentWrap {
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        border-radius: 5rem;
        background-color: #d0d3f7;
        margin-bottom: 0.5rem;

        @include md-tablet {
          text-align: center;
          margin: 0 auto;
        }
      }

      h6 {
        font-size: 2rem;

        @include md-tablet {
          text-align: center;
        }
      }

      p {
        @include paraTypo;

        @include md-tablet {
          text-align: center;
        }
      }
    }
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
    margin: 0 0 0 auto;

    @include md-tablet {
      grid-area: 1 / 1 / 2 / 2;
      margin: unset;
    }

    @include x-767-tablet {
      grid-area: 1 / 1 / 2 / 2;
    }

    .contentWrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include md-tablet {
        align-items: center;
      }

      p {
        width: 74%;
        text-align: right;

        @include md-tablet {
          text-align: center;
        }
      }
    }
  }

  .div2 {
    grid-area: 2 / 1 / 3 / 2;
    margin: 0 0 0 auto;

    @include md-tablet {
      grid-area: 1 / 2 / 2 / 3;
      margin: unset;
    }

    @include x-767-tablet {
      grid-area: 2 / 1 / 3 / 2;
    }

    .contentWrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include md-tablet {
        align-items: center;
      }

      p {
        width: 87%;
        text-align: right;

        @include md-tablet {
          text-align: center;
        }
      }
    }
  }

  .div3 {
    grid-area: 1 / 3 / 2 / 4;

    @include md-tablet {
      grid-area: 2 / 1 / 3 / 2;
    }

    @include x-767-tablet {
      grid-area: 3 / 1 / 4 / 2;
    }
  }

  .div4 {
    grid-area: 2 / 3 / 3 / 4;

    @include md-tablet {
      grid-area: 2 / 2 / 3 / 3;
    }

    @include x-767-tablet {
      grid-area: 4 / 1 / 5 / 2;
    }
  }

  .div5 {
    grid-area: 1 / 2 / 2 / 3;
    width: 100%;
    position: relative;

    @include md-tablet {
      display: none;
    }

    .spinner {
      animation: rotate 70s linear infinite;
      -webkit-animation: rotate 70s linear infinite;
      -moz-animation: rotate 70s linear infinite;
      position: relative;
      pointer-events: none;
    }

    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate {
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    @-moz-keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }

    .midImagWrap {
      width: 60rem;
      height: 51rem;
      position: absolute;
      top: 15%;
      left: -15%;
      pointer-events: none;

      @include d-1366-desktop {
        top: 28%;
        left: -10%;
        width: 54rem;
      }

      img {
        width: 100%;
        height: auto;
        transform: scale(0.89);
        pointer-events: none;
      }
    }
  }
}

// Feature Slider Section End

// Window Pro 11 Section Start
#windowProSection {
  padding: 10rem 0;
  background-color: #e0e0e0;

  @include d-1366-desktop {
    padding: 10rem 0 6rem;
  }

  @include d-desktop {
    padding: 10rem 0 9rem;
  }

  @include x-767-tablet {
    padding: 3rem 1rem;
  }

  .custRow {
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      background-image: url(../images/globeline.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
      width: 72%;
      height: 75rem;
      position: absolute;
      left: -200px;
      top: -9%;
      z-index: 9;
      pointer-events: none;
      animation: rotate 220s linear infinite reverse;
      -webkit-animation: rotate 220s linear infinite reverse;
      -moz-animation: rotate 220s linear infinite reverse;

      @include d-1366-desktop {
        width: 66%;
        height: 58rem;
        left: -145px;
        top: -5%;
      }

      @include d-desktop {
        width: 68%;
        height: 58rem;
        left: -100px;
        top: -5%;
      }

      @include md-tablet {
        content: none;
      }
    }

    &::after {
      content: "";
      background-image: url(../images/globemaine2.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
      width: 49%;
      height: 65rem;
      position: absolute;
      left: -50px;
      top: 0%;
      pointer-events: none;
      animation: rotate 240s linear infinite;
      -webkit-animation: rotate 240s linear infinite;
      -moz-animation: rotate 240s linear infinite;

      @include d-1366-desktop {
        width: 45%;
        height: 51rem;
        left: -30px;
        top: 2%;
      }

      @include d-desktop {
        width: 48%;
        height: 46rem;
        left: -5px;
        top: 7%;
      }

      @include md-tablet {
        content: none;
      }
    }

    .leftImgWrap {
      .lapImgWRap {
        width: 100%;
        height: 100%;
        z-index: 10;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }

      @include md-tablet {
        display: none;
      }
    }

    .winProTitleWrap {
      @include md-tablet {
        text-align: center;
      }

      h2 {
        @include mainTitle;
        margin-bottom: 1rem;

        @include x-767-tablet {
          font-size: 2.6rem;
        }
      }

      p {
        @include paraTypo;
        margin-bottom: 0;

        @include x-767-tablet {
          font-size: 1.6rem;
        }
      }
    }

    .winProList {
      margin-top: 3rem;

      ul {
        padding-left: 0;

        &.secondList {
          padding-left: 4rem;

          @include x-767-tablet {
            padding-left: 0;
          }
        }

        li {
          margin-bottom: 4rem;

          @include md-tablet {
            text-align: center;
          }

          &:last-child {
            margin-bottom: 0;
          }

          h5 {
            font-size: 2.6rem;
            margin-bottom: 0.2rem;

            @include x-767-tablet {
              font-size: 2rem;
            }
          }

          p {
            @include paraTypo;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Window Pro 11 Section End

// Solaris Benefits Section Start
#solarisBenefits {
  padding: 10rem 0 6rem;
  background-color: #f2f2ff;

  .mobView {
    @include x-767-tablet {
      display: none;
    }
  }

  @include x-767-tablet {
    padding: 3rem 1rem 4rem;
  }

  .sectionTitleBox {
    @include md-tablet {
      text-align: center;
    }

    h2 {
      @include mainTitle;
      margin-bottom: 1rem;

      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }

    p {
      @include paraTypo;
      margin-bottom: 0;
      width: 80%;

      @include md-tablet {
        margin: 0 auto;
        font-size: 1.6rem;
      }
    }
  }

  .commBtnWrap {
    @include md-tablet {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
    }
  }

  .leftImgWrap {
    position: relative;

    .svgLine {
      position: absolute;
      right: 31.5%;
      width: 29%;
      top: 50%;
      stroke-width: 0.4rem;
      pointer-events: none;
    }

    .topImgContentWrap {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      top: 8%;
      padding: 0 3rem;

      h6 {
        font-size: 2rem;
        line-height: 1.4;
      }

      .leftBoxOverlay {
        height: 8rem;
        width: 50%;
        background-color: #205cdc;
        padding: 1rem;
        border-radius: 0.4rem;

        @include d-desktop {
          width: 68%;
        }

        @include md-tablet {
          width: 50%;
        }

        span {
          font-size: 1.4rem;
          color: #fff;

          @include d-desktop {
            font-size: 1.2rem;
          }

          @include md-tablet {
            font-size: 1.4rem;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .leftBtmImg {
    position: relative;

    .overLay {
      position: absolute;

      &.overlayText1 {
        top: 12%;
        left: 8%;
        width: 70%;

        @include x-767-tablet {
          top: 10%;
          left: 8%;
          width: 85%;
        }
      }

      &.overlayText2 {
        top: 5%;
        left: 5%;
        width: 60%;

        @include x-767-tablet {
          width: 85%;
        }
      }

      h6 {
        font-size: 2rem;
        line-height: 1.4;

        @include x-767-tablet {
          font-size: 1.6rem;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Solaris Benefits Section End

// Prgressive Slider Section Start
.PurePerformance-home {
  position: absolute;
  bottom: 12%;
  left: 2%;


  @include desktop-l {
    bottom: 30px;
    left: 455px;
  }

  @include d-desktop {
    bottom: 10px;
    left: 15px;
  }

  @include tablet-land {
    bottom: 1px;
  }

  @include mobile {
    bottom: -28px;
    left: -135px;
  }
}

.slideWrapping .CommonButton {
  position: absolute;
  top: 100px;
}




// Prgressive Slider Section End

// Custom Carousel Section Start
#bookAmtCarousel {
  padding: 10rem 0;
  background-color: #f2f2ff;

  @include x-767-tablet {
    padding: 3rem 1rem;
  }

  .sectionTitleWrap {
    @include md-tablet {
      text-align: center;
    }

    @include x-767-tablet {
      margin-bottom: 4rem;
    }

    h2 {
      @include mainTitle;
      margin-bottom: 1rem;

      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }

    p {
      @include paraTypo;
      margin-bottom: 0;
      width: 60%;

      @include md-tablet {
        width: 80%;
        margin: 0 auto;
        font-size: 1.6rem;
      }
    }
  }
}

.slider-container {

  width: 100%;
  overflow: hidden;

  .buttons {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;

    @include mobile {
      display: none;
    }

    button {
      background-color: transparent;
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1rem solid #5d5d5d;

      @include x-767-tablet {
        height: 4rem;
        width: 4rem;
      }

      &:first-child {
        span {
          transform: rotate(-180deg);
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .slider {
    transition: transform 0.5s ease-in-out;

    .row {
      padding: 1rem 0;
    }

    .resCard {
      @include d-desktop {
        width: 50%;
      }

      @include x-767-tablet {
        width: 100%;
      }
    }

    .boxed {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 40.1rem;
      width: 100%;
      border: 0;
      position: relative;
      box-shadow: 0px 0px 15px -10px #757575;
      border-radius: 0.6rem;
      overflow: hidden;

      .rightTopBox {
        position: absolute;
        right: 1.5rem;
        top: 1rem;

        .rightopboxspace {
          padding-right: 10px;
          font-size: 14px;
        }

        h5 {
          font-size: 2rem;
          color: #a2b1d0;
          margin-bottom: 0;
        }

        h6 {
          margin-bottom: 0;
          color: #919191;
          font-size: 1.6rem;
        }
      }

      .leftTopBox {
        position: absolute;
        left: 1.5rem;
        top: 4.5rem;

        h5 {
          font-size: 2rem;
          color: #a2b1d0;
          margin-bottom: 0;
        }

        h6 {
          margin-bottom: 0;
          color: #919191;
          font-size: 1.6rem;
        }
      }

      .midlBox {
        position: absolute;
        top: 50%;
        left: 2rem;
        width: 60%;

        h4 {
          width: 300px;
          font-size: 1.8rem;
          text-align: center;
          margin-top: 94px;
        }

        p {
          @include paraTypo;
          font-size: 1.4rem;
        }
      }
    }
  }
}

// mobile btn visible
.bookbtncenter-home {
  @include xu-desktop {
    display: none;
  }

  @include mobile {
    display: block;
  }

  width: 100%;
  overflow: hidden;

  .buttons {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 2rem;

    button {
      background-color: transparent;
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1rem solid #5d5d5d;


      &:first-child {
        span {
          transform: rotate(-180deg);
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .slider {
    transition: transform 0.5s ease-in-out;

    .row {
      padding: 1rem 0;
    }

    .boxed {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 40.1rem;
      width: 100%;
      border: 0;
      position: relative;
      box-shadow: 0px 0px 15px -10px #757575;
      border-radius: 0.6rem;
      overflow: hidden;

      .rightTopBox {
        position: absolute;
        right: 1.5rem;
        top: 1rem;

        .rightopboxspace {
          padding-right: 10px;
          font-size: 14px;
        }

        h5 {
          font-size: 2rem;
          color: #a2b1d0;
          margin-bottom: 0;
        }

        h6 {
          margin-bottom: 0;
          color: #919191;
          font-size: 1.6rem;
        }
      }

      .leftTopBox {
        position: absolute;
        left: 1.5rem;
        top: 4.5rem;

        h5 {
          font-size: 2rem;
          color: #a2b1d0;
          margin-bottom: 0;
        }

        h6 {
          margin-bottom: 0;
          color: #919191;
          font-size: 1.6rem;
        }
      }

      .midlBox {
        position: absolute;
        top: 50%;
        left: 2rem;
        width: 60%;

        h4 {
          width: 300px;
          font-size: 1.8rem;
          text-align: center;
          margin-top: 94px;
        }

        p {
          @include paraTypo;
          font-size: 1.4rem;
        }
      }
    }
  }
}



// Custom Carousel Section End

// Pre Booking Section Start
#preOrderBookingSection {
  padding: 4rem 0 0;
  background-image: url(../images/prebooking-gradients.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 564px;

  @include xu-desktop {
    height: 631px;
  }

  @include d-1366-desktop {
    height: 564px;
  }

  @include d-desktop {
    height: 633px;
  }

  @include mobile {
    height: 583px;
  }

  @include xssm-mobile {
    height: 600px;
  }

  @include xss-mobile {
    height: 590px;
  }

  // @include x-767-tablet {
  //   padding: 4rem 1rem 0;
  // }

  .sectionTitleBox {
    // margin-bottom: 3rem;

    h2 {
      @include mainTitle;
      margin-bottom: 1rem;
      text-align: center;

      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }

    p {
      @include paraTypo;
      margin-bottom: 0;
      width: 55%;
      margin: 0 auto;
      text-align: center;

      @include md-tablet {
        width: 80%;
      }

      @include x-767-tablet {
        font-size: 1.6rem;
      }
    }
  }

  .halfLaptopWrap {
    position: relative;

    @include desktop {
      height: 500px;
    }

    @include tablet-land {
      height: 500px;
    }

    @include mobile {
      height: 400px;
    }

    @include xssm-mobile {
      height: 400px;
    }

    @include xss-mobile {
      height: 389px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .bookInputWrap {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 55%;

      @include desktop {
        top: 41%;
        left: 53%;
        width: 70%;
      }


      @include tablet-land {
        top: 36%;
        left: 54%;
        width: 73%;
      }

      @include mobile {
        width: 77% !important;
        top: 36%;
        left: 53%;
      }

      @include xssm-mobile {
        width: 76% !important;
        top: 36%;
        left: 52%;
      }

      @include xss-mobile {
        top: 33% !important;
      }

      form {
        width: 100%;
        margin-bottom: 2rem;

        .form-control {
          height: 5rem;
          position: relative;
          border-radius: 5rem;
          padding: 0 1.5rem;
          font-size: 1.6rem;
          color: #919191;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .bookBtn {
          background: #2d7ce7;
          color: #fff;
          height: 5rem;
          width: max-content;
          border-radius: 5rem;
          border: 0;
          font-size: 1.6rem;
          position: absolute;
          right: 0;
          top: 0;
          padding: 0 1rem;

          @include x-767-tablet {
            font-size: 1.2rem;
          }
        }
      }

      p {
        @include paraTypo;
        color: #fff;
        text-align: center;
        width: 80%;
        margin: 0 auto;

        @include x-767-tablet {
          font-size: 1.4rem;
          display: none;
        }
      }
    }
  }
}

// Pre Booking Section End

// Footer Section Start
#footer {
  padding: 6rem 0 10rem;
  background-image: url(../images/foobg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: auto;
  position: relative;

  // @include x-767-tablet {
  //   padding: 8rem 1rem 21rem;
  //   height: 100%;
  //   background-size: cover;
  // }
  @include d-1366-desktop {
    background-size: 100% 100%;
  }

  .footerWrap {
    justify-content: space-between;
    padding: 0 2rem;

    @include d-1366-desktop {
      padding: 0 5rem;
    }

    @include d-desktop {
      padding: 0 6rem;
    }

    @include x-767-tablet {
      gap: 4rem;
    }

    .foologoWrap {
      a {
        @include x-767-tablet {
          width: 100%;
          height: 100%;
          display: flex;
        }

        img {
          width: 40%;

          @include x-767-tablet {
            margin: 0 auto;
          }
        }
      }
    }

    .commBtnWrap {
      @include x-767-tablet {
        display: flex;
        justify-content: center;
      }

      button {
        margin-top: 0;
      }
    }
  }

  .padTop {
    padding: 5rem 2rem 0;
    justify-content: space-between;

    // align-items: center;
    @include d-1366-desktop {
      padding: 5rem 5rem 0;
    }

    @include d-desktop {
      padding: 5rem 6rem 0;
    }

    @include x-767-tablet {
      padding: 3rem 1rem 10rem;
      gap: 4rem;
    }
  }

  .subscriptionBox {
    @include x-767-tablet {
      text-align: center;
    }

    h6 {
      @include mainTitle;
      font-size: 2.4rem;

      @include x-767-tablet {
        font-size: 2rem;
      }
    }

    p {
      @include paraTypo;
      line-height: 1.2;

      @include d-1366-desktop {
        font-size: 1.6rem;
      }

      @include x-767-tablet {
        font-size: 1.4rem;
      }
    }

    .subscriptionFormWrap {
      position: relative;
    }

    form {
      .form-control {
        width: 80%;
        height: 3rem;
        border-bottom: 0.1rem solid #000 !important;
        position: relative;
        background-color: transparent;
        border-radius: 0;
        border: 0;
        font-size: 1.6rem;
        color: #919191;
        margin-top: 2rem;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .subBtn {
        position: absolute;
        right: 20%;
        top: 0;
        background-color: transparent;
        border: 0;
      }
    }
  }

  .fooLinksWrap {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include x-767-tablet {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }

      li {
        a {
          @include paraTypo;
          @include paraTypo-hover;

          @include x-767-tablet {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .cirtificateBox {
    @include x-767-tablet {
      text-align: center;
    }

    h6 {
      font-family: "AeonikTRIAL-Regular";
      font-size: 2rem;
      color: #919191;

      @include x-767-tablet {
        font-size: 1.6rem;
      }
    }
  }

  .cpyWriteText {
    @include x-767-tablet {
      text-align: center;
    }

    p {
      font-family: "AeonikTRIAL-Bold";
      color: #1d1d1f;
      font-size: 1.8rem;
      margin-bottom: 0;

      @include x-767-tablet {
        font-size: 1.4rem;
      }
    }
  }

  .copyWriteSection {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 5%;
    left: 5px;
    padding: 0 12rem;

    @include d-1366-desktop {
      padding: 0 11rem;
    }

    @include d-desktop {
      padding: 0 9rem;
    }

    @include x-767-tablet {
      padding-top: 3rem;
      gap: 2rem;
      bottom: 0%;
    }

    .socialLinksWrap {
      ul {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        li {
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            border-radius: 5rem;
            background: #2d4cc3;

            @include x-767-tablet {
              width: 3rem;
              height: 3rem;
            }

            svg {
              @include x-767-tablet {
                height: 1.6rem;
              }
            }

            &:hover {
              background-color: #758add;

              svg {
                @include x-767-tablet {
                  height: 1.6rem;
                }

                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Footer Section End

.h100 {
  height: 50vh;

  &:nth-child(even) {
    background-color: red;
  }
}

.myaccountspan {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myaccountspan p {
  font-size: 20px;
  padding-right: 40px;
  font-weight: 800;
}

// login


.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.login-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-form input:focus {
  border-color: #007bff;
  outline: none;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-form button {
  padding: 10px 15px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-form button:hover:not(:disabled) {
  background-color: #0056b3;
}

// Roshan Added New CSS

// .slider-container{
//   position: relative;
//   width: 90%;
// }
.main-image-container {
  height: 30rem;
  position: relative;
  text-align: center;
  width: 50rem;
}

.main-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.color-container {
  margin-top: -23px;
  margin-right: 55px;
  display: flex;
  justify-content: center;
}

.colorBox {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  cursor: pointer;
}

.thumbnail-slider-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 250px;
}

.thumbNailBox {
  margin: 0 5px;
  cursor: pointer;
}

.thumbnail {
  width: 60px;
  height: 50px;
}

.next-thumb-button1 {
  background-color: #2d4cc3;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 18px;
}

.btn-left {
  text-align: end !important;
}

/* ************ */
.product-details-center {
  text-align: center;
  padding-top: 15px;
  line-height: 50px;
}

.slider-container-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    margin-left: 0px !important;
  }
}

.featureSlideRight {
  // margin-right: 100px;

  @include mobile {
    margin-right: 0px !important;
  }
}

/* ***************Login********************** */

.login-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.login-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 21px;
  font-size: 16px;
}

.login-form input:focus {
  border-color: #007bff;
  outline: none;
}

.login-form button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  width: 61%;
  border-radius: 43px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-bottom: 10px;
}

/* *********Logout********* */
.logout-btn {
  border-radius: 10px;
  border: none;
}

/* ***********Protected delay page********* */
.redirect-message {
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #333;
  background-color: #f8f9fa;
  /* border: 1px solid #ddd; */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.redirect-message .icon {
  font-size: 50px;
  color: #28a745;
  margin-bottom: 10px;
}

.redirect-message p {
  margin: 0;
  line-height: 1.5;
}

/* ************My account********************* */


.my-account h1 {
  text-align: center;

}

// book images
.flex-nowrap .book-image img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  border-radius: 0.6rem;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 0px;

  background-color: #f4f5f2;
  padding: 21px;
}

.flex-nowrap .book-title {
  width: 100%;
  position: absolute;
  top: 135px;
  margin-left: 19px;

  @include xssm-mobile {
    top: 134px;
  }

  @include mobile {
    top: 137px;
  }

  @include xss-mobile {
    top: 116px;
  }

  @include desktop {
    top: 130px;
  }
}

.flex-nowrap .hr-line {
  width: 100%;
  position: absolute;
  bottom: 142px;

  @include xss-mobile {
    bottom: 149px;
  }
}

.flex-nowrap .price-book {
  color: #2c4cc3;
  position: absolute;
  bottom: 91px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.flex-nowrap .price-book h2 {
  font-size: 26px;
  font-weight: 700;
}

.flex-nowrap .book-description {
  font-size: 14px;
  position: absolute;
  bottom: 161px;
  margin-left: 19px;
}

.flex-nowrap .price-book h2 del {
  color: #8c8c8c;
  font-size: 18px;
}

.book-7courses {
  position: absolute;
  bottom: 20px;
}

.book-7courses h4 img {
  width: 30px;
  height: 20px;
}

.book-btn {
  position: absolute;
  bottom: 20px;
  right: 1.5rem;

  @include xu-desktop {
    bottom: 20px;
    right: 1.5rem;
  }

  @include d-desktop {
    bottom: 20px;
    right: 1.5rem;

  }

}

// **********Affiliate Page Start*******************
.affiliatebg {
  width: 100%;
  height: auto;
  background-image: url(../images/affiliatebg.png);
  background-repeat: no-repeat;
  background-size: cover;

  @include d-1366-desktop {
    height: 440px !important;
  }


  @include desktop-l {
    height: 517px;
  }

  @include xu-desktop {
    height: 513px;
  }

  @include d-desktop {
    height: 371px !important;
  }

  @include tablet-land {
    height: 391px !important;
  }

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.affiliatesec1main {
  @include mobile {
    font-size: 31px;
  }
}

.affiliatebg .affiliatesec1-para1 {
  width: 79%;
  font-size: 41px;
  font-weight: 900;
  color: black;
  font-family: "AeonikTRIAL-Bold";

  @include d-desktop {
    width: 81%;
    font-size: 31px;
  }

  @include tablet-land {
    width: 81%;
    font-size: 26px;
  }

  @include xssm-mobile {
    width: 100%;
  }
}


.affiliate-sec-1 .affiliatesec1-para1 .span-1 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 45px;
  font-weight: 900;
  color: #6d24ba;
  background: linear-gradient(to right, #6d24ba 0%, #96e0f3 25%, #a2b8f7 32%, #a65ff8 49%, #5949e0 77%, #313ed3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "AeonikTRIAL-Bold";
}

.affiliatespan2 {
  width: 77%;
  font-size: 19px;
}

.affiliatespan2 {
  width: 65%;
  font-size: 20px;

  @include tablet-land {
    width: 84%;
    font-size: 13px;
  }

  @include d-desktop {
    width: 82%;
    font-size: 17px;
  }
}

.affiliateTv img {
  @include tablet-land {
    height: 392px;
  }
}

// progressbar
.affiliateprogressmain {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 10px;
  position: relative;
  bottom: 67px;

  @include mobile {
    padding: 30px;
    bottom: 0px;
  }
}

.banner-affiliate-btn {
  display: flex;
  gap: 10px;
  z-index: 1;
  position: relative;
}

//************************* Affiliate section 2 *****************************
.AffiliatePageSection2 {
  width: 100%;
  height: auto;

  @include mobile {
    height: 60%;
  }
}

.AffiliatePageSection2 h2 {
  padding-top: 56px;
  padding-left: 40px;

  @include mobile {
    padding-top: 420px;
  }
}

.AffiliatePageSection2 .affiliatesection2 img {
  width: 110px;
  height: 110px;
}

.affiliatesection2 {
  display: flex;
}

.affiliatesection2 .affiliatesection2-span1 {
  font-size: 21px;
  color: black;
  font-weight: 900;
}

.affiliatesection2-div-2 {
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 27px;
}

.affiliatesection2-span2 {
  font-size: 19px;
}

.affiliatelinemain {
  position: relative;
  width: 2px;
  height: 120px;
}

.affiliateline {
  position: absolute;
  top: -36px;
  left: 55px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 69%, #b4b4b4 69%);
  background-size: 100% 28px;

  @include tablet-land {
    top: -66px;
  }

  @include xssm-mobile {
    top: -67px;
  }
}

.step {
  position: relative;
  bottom: 52px;

  @include tablet-land {
    bottom: 81px;
  }

  @include xssm-mobile {
    bottom: 70px;
  }
}

.affiliatelinespacing {
  position: absolute;
  top: -91px;
  left: 55px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 69%, #b4b4b4 69%);
  background-size: 100% 28px;

  @include tablet-land {
    top: -147px;
  }

  @include xssm-mobile {
    top: -137px;
  }
}

.step3 {
  position: relative;
   text-align: center;
   padding-top: 49px;
   padding-bottom:20px;

  @include tablet-land {
    bottom: 159px;
  }

  @include xssm-mobile {
    bottom: 151px;
  }
}

.ouraffiliateimg {
  padding-top: 56px;

  @include mobile {
    padding-top: 0px;
  }
}

.ouraffiliateimg img {
  width: 100%;
  height: 520px;

  @include d-desktop {
    height: 578px;
  }

  @include mobile {
    height: 404px;
    padding-bottom: 19px;
  }
}

// *****************Affiliate Page Section 3 *******************
.AffiliatePageSectionmain3 {
  width: 100%;
  height: 121vh;
  background-image: url('../images/affiliatesec3bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  @include mobile {
    display: none;
  }

  .AffiliatePageSectionmain3Mobile {
    width: 100%;
    height: 758px;
    background-image: url('../images/affiliatesec3bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  @include xu-desktop {
    height: 758px;
  }

  @include d-1366-desktop {
    height: 777px;
  }



  @include mobile {
    display: none;
  }
}

.Affiliatesec3head {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}

.Affiliatesec3head h2 {
  color: white;
  font-size: 27px;
}

.Affiliatesec3head p {
  width: 40%;
  color: #d9d9d9;
  font-size: 20px;
  margin: 0 auto;

  @include tablet-land {
    width: 60%;
    color: #d9d9d9;
    font-size: 17px;
  }
}

.affliateSec3imgInner img {
  width: 70%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 195px;

  @include desktop-l {
    width: 66%;
    height: 785px;
    left: 341px;
  }

  @include desktop {
    width: 70%;
    left: 17%;
  }

  @include xu-desktop {
    height: 656px;
  }

  @include d-1366-desktop {
    height: 675px;
  }

  @include d-desktop {
    width: 623px;
    height: 643px;
  }

  @include tablet-land {
    width: 70%;
    height: 685px;
    left: 105px;
  }
}

.affiliatesec3Box1 {
  width: 240px;
  height: 150px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  position: relative;
  left: 100px;
  top: 21px;

  @include desktop-l {
    left: 326px;
    top: 29px;
  }

  @include d-desktop {
    left: 18px;
  }

  @include tablet-land {
    width: 187px;
    height: 123px;
    top: 42px;
    left: 3px;
  }

  @include xssm-mobile {
    left: 8px;
  }

  @include xss-mobile {
    width: 200px;
  }
}

.affiliatesec3Boxinnerbtn {
  width: 200px;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  margin: 0px auto;
  padding: 7px;
  font-size: 16px;
  text-align: center;
  color: #42199a;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  @include tablet-land {
    width: 155px;
    height: 40px;
    font-size: 16px;
  }

  @include xssm-mobile {
    font-size: 16px;
  }
}

.affiliatesec3Box1 p {
  padding: 5px 14px;
  font-size: 17px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;

  @include tablet-land {
    font-size: 13px;
  }
}

.affiliatesec3Box-3 {
  width: 240px;
  height: 150px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  position: relative;
  left: 25px;
  top: 82px;

  @include desktop-l {
    left: 190px;
    top: 131px;
  }

  @include d-desktop {
    top: 142px;
    left: 1px;
  }

  @include xu-desktop {
    top: 72px;
  }

  @include tablet-land {
    width: 162px;
    height: 118px;
    left: -8px;
    top: 134px;
  }
}

.affiliatesec3Box-3 p {
  padding: 5px 14px;
  font-size: 17px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;

  @include tablet-land {
    font-size: 12px;
  }
}

.affiliatesec3Box-5 {
  width: 240px;
  height: 150px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  position: absolute;
  left: 100px;
  bottom: 40px;

  @include desktop-l {
    left: 372px;
    bottom: 55px;
  }

  @include d-desktop {
    left: 22px;
    bottom: 42px;
  }

  @include tablet-land {
    left: 4px;
    bottom: 57px;
    width: 195px;
    height: 123px;
  }
}

@include xu-desktop {}


.affiliatesec3Box-5 p {
  padding: 5px 14px;
  font-size: 17px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;

  @include tablet-land {
    font-size: 13px;
  }
}

.affiliatesec3Box-2 {
  width: 240px;
  height: 150px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  position: absolute;
  top: 150px;
  right: 125px;

  @include desktop-l {
    right: 556px;
  }

  @include xu-desktop {
    top: 157px;
    right: 112px;
  }

  @include d-1366-desktop {
    right: 94px;
  }

  @include d-desktop {
    top: 190px;
    right: 90px;
  }

  @include tablet-land {
    width: 194px;
    height: 121px;
    top: 169px;
    right: 20px;
  }

}

.affiliatesec3Box-2 p {
  padding: 5px 14px;
  font-size: 17px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;

  @include tablet-land {
    font-size: 13px;
  }
}

.affiliatesec3Box-4 {
  width: 240px;
  height: 150px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  position: absolute;
  bottom: 250px;
  right: 35px;

  @include desktop-l {
    width: 240px;
    height: 150px;
    bottom: 293px;
    right: 383px;
  }

  @include xu-desktop {
    bottom: 247px;
    right: 16px;
  }

  @include d-1366-desktop {
    bottom: 250px;
    right: 8px;
  }

  @include d-desktop {
    bottom: 244px;
    right: 21px;
  }

  @include tablet-land {
    width: 179px;
    height: 123px;
    bottom: 264px;
    right: 1px;

  }
}

.affiliatesec3Box-4 p {
  padding: 5px 14px;
  font-size: 17px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;

  @include tablet-land {
    font-size: 13px;
  }
}

.affiliatesec3Box-Subsequent {
  width: 240px;
  height: 165px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  position: absolute;
  bottom: 45px;
  right: 100px;

  @include desktop-l {
    right: 500px;
  }

  @include xu-desktop {
    bottom: 45px;
    right: 93px;
  }

  @include d-1366-desktop {
    bottom: 40px;
    right: 68px;
  }

  @include d-desktop {
    height: 170px;
    bottom: 42px;
    right: 70px;
  }

  @include tablet-land {
    width: 204px;
    height: 114px;
    bottom: 51px;
    right: 4px;
  }

}

.affiliatesec3Box-Subsequent p {
  padding: 5px 14px;
  font-size: 17px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;

  @include tablet-land {
    font-size: 12px;
  }
}

.affiliatesec3Boxinner-Subsequentbtn {
  width: 200px;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  margin: 0px auto;
  padding: 7px;
  font-size: 14px;
  text-align: center;
  color: #42199a;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  @include tablet-land {
    width: 186px;
  }
}

// ***********Section 4 ****************
.affiliatesection4-main {
  width: 100%;
  height: 100vh;
  background-color: #fafafa;

  @include xu-desktop {
    height: 73vh;
  }

  @include d-1366-desktop {
    height: 100vh;
  }

  @include mobile {
    width: 100%;
    height: auto;
  }
}

.affiliatesection4-head {
  text-align: center;
  padding-top: 50px;

  @include mobile {
    display: none;
  }
}

.affiliatesection4-head h1 {
  font-size: 30px;
}

.affiliatesection4-head p {
  width: 50%;
  color: #ababab;
  font-size: 20px;
  margin: 0 auto;
  padding-bottom: 40px;

  @include mobile {
    width: 82%;
  }
}


.affiliatesection4-card1 {
  height: 370px;
  background-image: url('../images/affiliateprogram1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding-top: 3px;

}

.affiliatesection4-card1-btn {
  width: 200px;
  height: 50px;
  background-color: #3f48c0d3;
  border-radius: 50px;
  margin: 20px 30px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 10px 10px 10px 17px;

  @include tablet-land {
    margin: 20px 15px;
  }
}

.affiliatesection4-card1 p {
  color: rgba(0, 0, 0, 0.884);
  font-size: 22px;
  padding: 10px 0px 0px 10px;
}

// ************

.affiliatesection4-card2 {
  height: 370px;
  background-image: url('../images/affiliateprogram2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding-top: 3px;

  @include mobile {
    padding-top: 3px;
  }
}

.affiliatesection4-card1-btn-2 {
  width: 220px;
  height: 50px;
  background-color: #3f48c0d3;
  border-radius: 50px;
  margin: 20px 30px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 10px 10px 10px 17px;

  @include tablet-land {
    margin: 20px 2px;
  }
}

.affiliatesection4-card2 p {
  color: rgba(0, 0, 0, 0.884);
  font-size: 22px;
  padding: 10px 0px 0px 10px;
}

// ************
.affiliatesection4-card3 {
  height: 370px;
  background-image: url('../images/affiliateprogram3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding-top: 3px;

  @include mobile {
    padding-top: 3px;
  }
}

.affiliatesection4-card3 p {
  color: rgba(0, 0, 0, 0.884);
  font-size: 22px;
  padding: 10px 0px 0px 10px;
}

// price mobile 
.price-mobile-version {
  display: none;

  @include mobile {
    display: block;
  }
}

.rowgapsection4 {
  @include mobile {
    row-gap: 10px;
  }
}

// *****************Section 3 mobile version*************
.affiliatesection3Mobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.Affiliatesec3headMobile {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}

.Affiliatesec3headMobile h2 {
  text-align: center;
  font-size: 20px;
}

.Affiliatesec3headMobile p {
  text-align: center;
  font-size: 18px;

  @include xss-mobile {
    font-size: 16px;
  }
}

.section3mobilecardmain {
  display: flex;
  justify-content: start;
  position: relative;
}

.affliateSec3imgprice {
  position: absolute;
  right: 0;
  top: 35px;

  @include xss-mobile {
    right: -6px;
  }
}

.section3mobilecardmain2 {
  display: flex;
  margin-top: 40px;
  padding-right: 10px;
  color: white;
}

.affiliatesec3Box-2Mobile {
  width: 240px;
  height: 150px;
  border-radius: 20px;
  background-color: #624be8;
  padding-top: 15px;
  margin-left: 30px;

  @include xss-mobile {
    margin-left: 7px;
  }
}

.affiliatesec3Box-2Mobile p {
  color: white;
}

// ******************Affiliate Page  Sign Up***********************
.form-containerAffiliatesignUp {
  font-size: 15px;
  font-weight: 600;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-containerAffiliatesignUp .form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 13px;
  line-height: 3.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

// affiliatebtn sign up
.affiliatesignupbtn {
  background-color: #007bff;
  /* Primary color */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;

  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.affiliatesignupbtn:hover {
  background-color: #0056b3;
}

.affiliatesignupbtn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.paymentsuccessfull-btn {
  margin: 0 auto;
}

// *******Affiliate Details *******************
.affiliate-details-container {
  margin-top: 140px;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header styles */
.affiliate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.affiliate-header h2 {
  font-size: 24px;
  margin-bottom: 10px;

}


.affiliate-header .status-text {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.affiliate-header .unpaid-text {
  color: red;
  font-weight: bold;
}

/* Table styles */
.affiliate-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
}


.affiliate-table th,
.affiliate-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.affiliate-table th {
  background-color: #4CAF50;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
}

.affiliate-table td {
  color: #333;
  font-size: 13px;
}

.affiliate-table tbody tr:hover {
  background-color: #f1f1f1;
}


.affiliate-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.affiliate-header .status-text span {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: normal;
}

.affiliate-header .status-text span.active {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

// ************Carousel*******************

.carousel-container {
  position: relative;
}

.carousel-container img {
  width: 100%;
  height: 705px;

  @include desktop-l {
    height: 1000px;
  }

  @include mobile {
    height: 223px;
  }

  @include tablet-land {
    height: auto;
  }
}

.carousel-container .carousel-container-slider1-man img {
  width: 80%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 10%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include desktop-l {
    width: 69%;
    left: 18%;
  }

  @include xu-desktop {
    width: 87%;
    left: 9%;
  }

  @include desktop {
    width: 93%;
    left: 5%;
  }

  @include tablet-land {
    width: 91%;
    left: 5%;
  }
}


// ******************Admindashboard********************
.app-header {
  background-color: #3f51b57a;
  color: white;
  padding: 10px 20px;
}

.sidebar {
  width: 240px;
  background-color: #f4f4f4;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  cursor: pointer;
}

.sidebar li:hover {
  background-color: #ddd;
  /* Highlight on hover */
}

.main-content {
  margin-left: 240px;
  padding: 20px;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 200px;
  /* Adjust card size */
  text-align: center;
}



.dashboard-container {
  padding: 20px;
  margin: 50px;
  border-radius: 8px;
  border: 2px solid #ffffff;
  background-color: white;
}

.dashboard-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.dashboard-container h2 {
  margin-top: 30px;
  font-size: 2.5rem;
}

/* Search input and button styling */
.search-container {
  margin-bottom: 20px;
}

.search-container input {
  padding: 12px;
  width: 300px;
  margin-right: 10px;
  border: 1px solid #ccc;
  font-size: 12px;
  border-radius: 4px;
}

.search-container button {
  padding: 12px 19px;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.search-container button:hover {
  background-color: #0056b3;
}

/* Table styling */
.affiliates-table {
  width: 100%;
  border-collapse: collapse;
}

.affiliates-table th,
.affiliates-table td {
  border: 1px solid #ddd;
  padding: 11px;
  text-align: left;
  font-family: "AeonikTRIAL-Regular";
  font-size: 13px;
}

.affiliates-table th {
  font-family: "AeonikTRIAL-Regular";
  padding: 14px 23px 9px 14px;
  background-color: #f9fafb;
  font-size: 12px;
}

.affiliates-table td button {
  border: none;
  font-size: 17px;
  border-radius: 4px;
  cursor: pointer;
}


.affiliates-table td button.delete {
  background-color: #dc3545;
}

.affiliates-table td button.delete:hover {
  background-color: #c82333;
}

/* Modal styling */
.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.react-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  height: 400px;
  overflow-y: scroll;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.react-modal-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.react-modal-content label {
  display: block;
  margin-bottom: 10px;
}

.react-modal-content input {
  padding: 8px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-modal-content-select {
  padding: 8px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-modal-content button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.react-modal-content button:hover {
  background-color: #0056b3;
}

.react-modal-content button.cancel {
  background-color: #dc3545;
}

.react-modal-content button.cancel:hover {
  background-color: #c82333;
}

.dashboard-appbar ul li {
  width: 100%;
  height: 40px;
  border: 2px solid rgba(0, 0, 0, 0.301);
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

// ************************Orders Dashboard**********************

.dashboard-container {
  z-index: 0;
  padding: 20px;
  border-radius: 8px;
}

.orderdashboarddetails h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.orderdashboarddetails .search-container {
  margin-bottom: 20px;
}

.orderdashboarddetails .search-container input {
  padding: 11px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.orderdashboarddetails .search-container button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.orderdashboarddetails .search-container button:hover {
  background-color: #0056b3;
}

.orderdashboarddetails .orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.orderdashboarddetails .orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  font-size: 12px;
  font-family: 'AeonikTRIAL-Regular';
  padding: 15px;
  text-align: left;
}


.orderdashboarddetails .orders-table th {
  background-color: #f9fafb;
  padding: 20px;
  font-size: 13px;
  color: black;
}

.orderdashboarddetails .orders-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.orderdashboarddetails .orders-table tr:hover {
  background-color: #ddd;
}

.orderdashboarddetails .orders-table .delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.orderdashboarddetails .orders-table .delete:hover {
  background-color: #c82333;
}

.orderdashboarddetails .react-modal-content {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
}

.orderdashboarddetails .react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.orderdashboarddetails form label {
  display: block;
  margin-bottom: 10px;
}

.orderdashboarddetails form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.orderdashboarddetails button[type="button"] {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.orderdashboarddetails button[type="button"]:hover {
  opacity: 0.8;
}

.orderdashboarddetails .cancel {
  background-color: #6c757d;
  color: white;
}

.orderdashboarddetails .cancel:hover {
  background-color: #5a6268;
}

.orderdashboarddetails .editbtnorders {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

// ***************product css *******************
.Adminproductpage .dashboard-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Adminproductpage h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.Adminproductpage .search-container {
  display: flex;
  margin-bottom: 20px;
}

.Adminproductpage .search-container input {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 13px;
  border-radius: 4px;
  width: 28%;
}

.Adminproductpage .search-container button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.Adminproductpage .search-container button:hover {
  background-color: #0056b3;
}

.Adminproductpage h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #444;
}

.Adminproductpage table {
  width: 100%;
  border-collapse: collapse;
  /* Ensure borders are collapsed */
  margin-bottom: 20px;
}

.Adminproductpage th,
.Adminproductpage td {
  padding: 15px;
  text-align: left;
  font-size: 13px;
  border: 1px solid #ccc;
  font-family: 'AeonikTRIAL-Regular';
}

.Adminproductpage th {
  background-color: #f9fafb;
  color: black;
  font-family: 'AeonikTRIAL-Regular';
  font-size: 14px;
}


.Adminproductpage tbody tr:hover {
  background-color: #f1f1f1;
}

.Adminproductpage .editbtnproducts,
.delete {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Adminproductpage .editbtnproducts {
  background-color: #28a745;
  color: white;
}

.Adminproductpage .delete {
  background-color: #dc3545;
  color: white;
  margin-left: 5px;
}

.Adminproductpage .editbtnproducts:hover {
  background-color: #218838;
}

.Adminproductpage .delete:hover {
  background-color: #c82333;
}

/* Modal Styles */
.Adminproductpage .react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Adminproductpage .react-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.Adminproductpage .react-modal-content h2 {
  margin-top: 0;
}

.Adminproductpage .react-modal-content form {
  display: flex;
  flex-direction: column;
}

.Adminproductpage .react-modal-content label {
  margin: 10px 0 5px;
}

.Adminproductpage .react-modal-content input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Adminproductpage .react-modal-content button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.Adminproductpage .react-modal-content button:hover {
  background-color: #0056b3;
}

// *****Admin User*****
.adminuser-table {
  border: 1px solid gray;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  color: white;
  text-align: left;
  background-color: #fff;
}


.table-containereee {
  overflow-x: auto;
}

.adminuser-table tr th {
  border: 1px solid #ccc;
  padding: 19px;
  color: rgba(0, 0, 0, 0.836);
  background-color: #f9fafb;
  font-size: 13px;
}

.adminuser-table tbody td {
  font-family: 'AeonikTRIAL-Regular';
  padding: 19px;
  font-size: 13px;
  border: 1px solid #ccc;
  color: black;

}

.adminuser-table .editbtnuser {
  padding: 8px 12px;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  margin-right: 7px;
}

.adminuser-table .delete {
  padding: 8px 12px;
  background-color: red;
  border: none;
  cursor: pointer;
  color: white;
}

.dashboardmainpage {
  display: flex;
  justify-content: center;
  color: red;
}

.app-header button {
  position: absolute;
  top: 5px;
  right: 41px;
  width: 70px;
  outline: none;
  padding: 8px;
  font-size: 13px;
  border-radius: 9px;
  border: 1px solid red;
}

.carousel-container-slider1-clouds img {
  width: 573px;
  height: 215px;
  position: absolute;
  left: 369px;
  top: 143px;
  z-index: 1;
  transform: translate(-.425px, -0.175px);
  transition: transform 1s ease, top 1s ease;

  @include xu-desktop {
    width: 684px;
    height: 225px;
    left: 422px;
    top: 92px;
  }

  @include d-1366-desktop {
    left: 385px;
    top: 92px;
  }

  @include desktop {
    left: 268px;
    top: 232px;
    width: 531px;
    height: 215px;
  }

  @include tablet-land {
    width: 399px;
    height: 159px;
    left: 203px;
    top: 70px;
  }

  @include mobile {
    width: 225px;
    height: 92px;
    left: 99px;
    top: 48px;
  }

  @include xssm-mobile {
    width: 164px;
    height: 62px;
    left: 107px;
    top: 70px;
  }

  @include xss-mobile {
    width: 150px !important;
    height: 54px !important;
    left: 89px !important;
    top: 89px !important;
  }
}

.carousel-container-slider1-clouds img:hover {
  transform: translate(0, 5px);
  // top:5px;
}

.carousel-indicators {
  display: none !important;
}

.carousel-container-para-lift {
  position: absolute;
  bottom: 161px;
  left: 431px;
  text-align: center;

  @include desktop-l {
    bottom: 189px;
    left: 879px;

  }

  @include xu-desktop {
    left: 461px;
  }

  @include desktop {
    bottom: 113px;
    left: 307px;
  }

  @include tablet-land {
    width: 60%;
    bottom: 77px;
    left: 169px;
  }

  @include mobile {
    bottom: 23px;
    left: 119px;
    text-align: center;
  }

  @include xss-mobile {
    left: 91px !important;
  }
}

.carousel-container-para-right {
  position: absolute;
  bottom: 88px;
  right: 56px;

}

.carousel-container-para-liftpara1 {
  width: 65%;
  font-size: 40px;
  color: white;
  font-weight: 900;
  line-height: 67px;

  @include xu-desktop {
    font-size: 71px;
    line-height: 80px;
  }

  @include d-1366-desktop {
    font-size: 65px;
  }


  @include desktop {
    font-size: 67px;
    line-height: 67px;
  }

  @include tablet-land {
    width: 97%;
    font-size: 42px;
    line-height: 44px;
  }

  @include mobile {
    font-size: 25px;
    line-height: 31px;
  }

  @include xssm-mobile {
    font-size: 17px;
    line-height: 27px;
  }

  @include xss-mobile {
    font-size: 17px;
  }
}

.carousel-container-para-liftpara2 {
  width: 60%;
  font-size: 30px;
  color: white;
}

.carousel-container-para-right {
  width: 60%;
  font-size: 30px;
  color: white;
}

.carousel-container-para-right {
  font-size: 40px;
  font-weight: 900;
}

.viewbtndashboard {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: rgba(0, 0, 255, 0.829) !important;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 6px;
  cursor: pointer;
}

.clientpagetermsandcondition label {
  display: flex;
  font-size: 14px;
  margin-bottom: -1px;
  gap: 7px;
  margin-top: -32px;

  @include xssm-mobile {
    font-size: 12px;
  }

  @include xss-mobile {
    font-size: 11px;
  }
}

.digitalcoursenumber {
  font-size: 1.8rem;
}

.handleShowBankDataClick {
  text-decoration: none;
  outline: none;
}

// **********************Dashboard start ************************

//***** logout dashboard
.logoutdashboard {
  background-color: rgba(0, 0, 255, 0.548);
  color: white;
  border: none;
  padding: 8px 10px;
  font-size: 16px;
  margin-right: 48px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logoutdashboard:hover {
  background-color: rgba(0, 0, 255, 0.527);
  transform: scale(1.05);
}

.logoutdashboard:active {
  background-color: #cc0000;
  transform: scale(0.98);
}

.dashboardswrapper {
  border: 1px solid red;
}

.dashboard {
  background-color: #f9fafb;
  height: 100vh;
}

.dashboard-header {
  width: 100%;
  display: flex;
  z-index: 1;
  position: fixed;
  top: 0px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  color: black;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08)
}

.toggle-btn {
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  padding-right: 690px;
}

.sidebar {
  width: 232px;
  position: fixed;
  left: -257px;
  font-size: 17px;
  top: 80px;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 15px;
  color: black;
  margin: 22px;
  transition: left 0.3s ease;
  box-shadow: rgba(245, 222, 179, 0.774);
  height: 82vh;
  background-color: #f8f9fa;
  overflow-y: scroll;
}

.sidebar.open {
  top: 68px;
  left: 0;
}

.dashboard-content {
  flex: 1;
  margin-left: 0;
  transition: margin-left 0.3s ease;
}

.sidebar-open .dashboard-content {
  margin-left: 200px;
  padding: 40px 0px 0px 40px;
  background-color: #f9fafb;
}

.sidebar-closed .dashboard-content {
  position: relative;
  top: 40px;
}

.logoutdashboard {
  background-color: rgba(0, 0, 0, 0.712);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.logoutdashboard:hover {
  background-color: gray;
}

.dashboardtogglecircle {
  width: 50px;
  height: 50px;
  border: 2px solid gray;
  border-radius: 50%;
}

.dashboardtogglecircle:hover {
  background-color: rgba(128, 128, 128, 0.397);
}

// *******pagination****
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls button:not(:disabled):hover {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 18px;
  margin: 0 10px;
}

.dropdowndashboard {
  padding: 7px;
  background: #007bff;
  font-size: 13px;
  color: white;
  outline: none;
  border: none;
  border-radius: 2px;
}

.deleteSelectedbtn {
  margin: 10px;
  padding: 5px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  transition: background-color 4s ease, transform 0.2s ease;
}

.deleteSelectedbtn:hover {
  background-color: gray;
}

.dashboardViewpaidtransaction {
  padding: 1px;
  outline: none;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
}

.dashboardAffiliateView {
  width: 100%;
}

.dashboardAffiliateViewInner {
  width: 100%;
  height: auto;
  border: 1px solid gray;
  box-shadow: 2px 2px 2px 2px gray;
  color: black;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.dashboardButtonBack {
  background-color: gray;
  padding: 8px 18px 8px 18px;
  margin: 10px;
  color: white;
  text-align: center;
  border-radius: 5px;
  border: none;
  font-size: 17px;
  font-weight: 600;
}

.dashboardButtonBack:hover {
  background-color: #000000;
  color: white;
}

.dashboardAffiliateEditInner {
  width: 100%;
  height: auto;
  border: 1px solid whitesmoke;
  box-shadow: 5px 4px 17px 2px whitesmoke;
  color: black;
  padding: 7px 7px 20px 7px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.dashboardAffiliateEditInner input {
  height: 4.5rem;
  padding: 0 1.5rem;
  font-size: 1.5rem;
  color: #181818;
  border: 1px solid groove;
}

.dashboardAffiliateEditInner_save {
  margin-right: 10px;
  padding: 2px 4px 2px 4px;
}

.dashboardAffiliateEditInner_save:hover {
  color: white;
  background-color: #00000071;

}

.dashboardAffiliateEditInner_cancel {
  padding: 1px 4px 1px 4px;
}

.dashboardAffiliateEditInner_cancel:hover {
  color: white;
  background-color: #00000071;
}

.ViewPaidTransaction_css {
  padding: 8px 4px 8px 4px;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  margin-left: 10px;
}

.ViewPaidTransaction_css:hover {
  background-color: white;
}

// *****************
.ViewPaidTrasactionTable table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  text-align: left;
}

.ViewPaidTrasactionTable th,
td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.ViewPaidTrasactionTable tbody td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.ViewPaidTrasactionTable th {
  background-color: #f2f2f2;
  color: #333;
}

.ViewPaidTrasactionTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ViewPaidTrasactionTable tr:hover {
  background-color: #f1f1f1;
}

.aboutnotification {
  width: 400px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid red;
}

.notificationmain_toast {
  width: 25%;
  height: 100px;
  padding: 20px 12px 12px 12px;
  align-items: center;
  display: flex;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px 10px 20px 34px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;

  @include desktop {
    width: 32%;
  }

  @include tablet-land {
    width: 50%;
  }

  @include mobile {
    width: 90%;
  }

  @include xss-mobile {
    width: 100%;
    // height: auto;
  }

}

.notificationmain_toast_btn {
  margin: 0px -6px 45px 2px;
  background-color: transparent;
  border: none;
  color: blue;
}

.notificationmain_toast_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.notificationmain_toast-icon {
  margin-top: -35px;
}

.homeTimer-countdown {
  width: 53%;
  height: 42px;
  background-color: #00008b;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  margin: 0 auto;
  bottom: 30px;
  position: fixed;
  left: 313px;
  top: 33px;
  z-index: 2;
  transition: top 1s ease-in-out;

  @include xu-desktop {
    top: 43px;
  }

  @include desktop {
    left: 314px;
  }

  @include tablet-land {
    left: 190px;
  }

  @include mobile {
    width: 65%;
    left: 74px;
    height: 65px;
  }
}

.homeTimer-countdown.show {
  top: 90px;

  @include xss-mobile {
    left: 61px;
  }

  @include xu-desktop {
    top: 87px;
  }

  @include tablet-land {
    top: 89px;
    padding-top: 10px;
  }

  @include mobile {
    top: 82px;
  }

  @include xssm-mobile {
    top: 89px;
  }
}

.timmermaincountdown h4 {
  font-size: 1.6rem;
}

//*************delete checkbox****************
.checkboxdeletebtn {
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.6;
  transition: 1.3s;
  margin-top: 10px;
}

.checkboxdeletebtn:hover {
  background-color: gray;
  opacity: 1;
  color: black;
}

.selectedCommissionIds {
  width: 100%;
  border: 1px solid red;
  height: 4.5rem;
  padding: 0 1.5rem;
  font-size: 1.6rem;
  color: #181818;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
}

.paymentButtonAdded {
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  opacity: 0.7;
  transition: 2s all;
  color: black;
  font-weight: 700;
}

.paymentButtonAdded:hover {
  background-color: #0057b377;
}

//***************page not found************
.not-foundPageMain {
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(at 50% 100%, #fff 25%, #d9e6ff 100%, rgba(226, 234, 247, 0.65) 100%);
  width: 100%;
  height: 80vh;
  padding: 9% 0 5%;
  text-align: center;
}

.not-foundPageMain h1 {
  font-size: 80px;
  text-align: center;
}

.not-foundPageMain p {
  font-size: 40px;
  text-align: center;
}

.not-foundPageMain a {
  font-size: 30px;
  text-align: center;
}

.bankverifyDashboard {
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
}

.editAffiliate-icon {
  font-size: 19px;
  padding-left: 5px;
  cursor: pointer;
}

.AffiliateDetails-overfole {
  overflow-y: auto;
}

.affiliateActivedashboard {
  margin-left: 20px;
  padding: 10px 20px 10px 13px;
  border-radius: 5px;
  font-size: 18px;
  background-color: #00008b80;
  color: white;
  text-align: center;
  border: none;
}

.affiliateActivedashboard:hover {
  background-color: gray;
  color: black;
}

.activeAffiliate-css_main {
  text-align: start;
  margin: 10px;
}

.activeAffiliate-css {
  width: 50%;
  padding: 8px;
  outline: none;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.651);
  margin-bottom: 10px;
}

.activeAffiliate-css_main button {
  padding: 4px 15px 2px 15px;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  border-color: 1px solid #d9d9d9;
  top: 15px;
  outline: none;
  background-color: white;
}

.custom-cancel-button-activeAffiliate {
  padding: 20px 10px 20px 10px;
  position: absolute;
  bottom: 37px;
  left: 123px;
}

//**************my account*********
.my-account {
  width: 90%;
  height: auto;
  // border:1px solid rgba(128, 128, 128, 0.815);
  margin: 0px auto;
}

.myorderAccountdata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  gap: 20px;

  @include mobile {
    display: inline-block;
  }

  @include xss-mobile {
    display: inline-block;
  }
}

.myorderAccountdata p {
  color: #383838;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  background-color: springgreen;
  font-weight: 600;

  @include mobile {
    font-size: 11px;
    text-align: center;
    margin-top: 20px;
    padding: 10px 10px 10px 10px;
  }
}

.myAccountDateformat {
  font-size: 14px;
}

.myAccount-order-item {
  width: 100%;
  height: auto;
  border: 1px solid rgba(128, 128, 128, 0.185);
  border-radius: 10px;
  padding-bottom: 10px;
}

.myAccount-order-item h3 {
  padding: 15px;
}

.OrderItem-main-imageChooese {
  display: flex;
  justify-content: space-around;

  @include mobile {
    display: inline-block;
  }
}

.OrderItem-main-imageChooesedivfirst {
  width: 100%;
  display: flex;

  @include mobile {
    display: inline-block;
  }
}

.OrderItemimageChooese-besideContentpara1 {
  line-height: 4px;

  @include mobile {
    line-height: 20px;
  }
}

.OrderItemimageChooese-besideContent2para1 {
  line-height: 4px;

  @include mobile {
    line-height: 20px;
  }
}

.OrderItemimageChooese {
  width: 112px;
  height: 114px;
  background-color: rgba(128, 128, 128, 0.466);
  border-radius: 10px;
  margin: 0px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    width: 210px;
    height: 131px;
    margin: 0 auto;
  }
}

.OrderItem-main-imageChooese img {
  width: 100px;
  height: 100px;

  @include mobile {
    width: 191px;
    height: 126px;
  }
}

.OrderItemimageChooese2 {
  width: 112px;
  height: 114px;
  background-color: rgba(128, 128, 128, 0.466);
  border-radius: 10px;
  margin: 0px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    width: 214px;
    height: 138px;
    margin: 0 auto;
  }
}

.OrderItemimageChooese2 img {
  width: 79px;
  height: 100px;

  @include mobile {
    width: 115px;
    height: 120px;
  }
}

.OrderItemimageChooese-besideContent {
  width: 40%;
  font-size: 16px;
  margin-left: 18px;

  @include mobile {
    padding-top: 10px;
    width: 88%;
  }
}

.my-account .boxgrayy {
  width: 20px;
  height: 20px;
  background-color: gray;
  border-radius: 3px;
  display: inline-block;
}

.my-account .boxBlack {
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 3px;
}

.OrderItemimageChooeseColor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.PreOrderConfirmStatic p {
  font-size: 20px;
}

.OrderItem-main-section2 {
  width: 100%;
  display: flex;

  @include mobile {
    display: inline-block;
  }
}

.OrderItemimageChooese-besideContent2 {
  padding-top: 18px;
  width: 40%;
  font-size: 16px;
  margin-left: 18px;

  @include mobile {
    width: 88%;
  }
}

.PreOrderConfirmStatic1 {
  width: 20%;
  font-size: 16px;
  line-height: normal;

  @include mobile {
    width: 60%;
    margin-left: 13px;
  }
}

.PreOrderConfirmStaticsection2 {
  width: 20%;
  line-height: normal;
  font-size: 16px;

  @include mobile {
    width: 60%;
    margin-left: 15px;
  }
}

.myorderAccountdataPayment {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @include mobile {
    display: none;
  }
}

.myorderAccountdataPaymentt h3 {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.822);
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8px 0px 8px 0px;
  text-align: center;
  background-color: gray;
  border: none;

  @include mobile {
    padding: 5px 0px 5px 0px;
  }
}

// ************Active Affiliate

.activeAffiliate-css_main {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.activeAffiliate-css_main h1 {
  font-size: 2em;
  color: #333;
}

.activeAffiliate-css_main label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.activeAffiliate-css_main .activeAffiliate-css {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.activeAffiliate-css_main .activeAffiliate-css:focus {
  border-color: #007bff;
  outline: none;
}

.activeAffiliate-css_main button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.activeAffiliate-css_main button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.activeAffiliate-css_main button:hover:not(:disabled) {
  background-color: #0056b3;
}

.activeAffiliate-css_main .error-message {
  color: red;
  margin-top: 5px;
}

//*********Admin Order************
.react-modal-content_Order {
  position: absolute;
  top: 53%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  height: 300px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: auto;

}

.adminorderpageform {
  width: 87%;
  padding: 10px;
}


.react-modal-content_Order h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.react-modal-content_Order .row {
  margin-bottom: 15px;
}

.react-modal-content_Order .mb-3 {
  margin-bottom: 15px;
}

.react-modal-content_Order label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.react-modal-content_Order .adminorderpageform {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.adminorderpageform:focus {
  border-color: #80bdff;
  outline: none;
}

.react-modal-content_Order .btn {
  margin-top: 10px;
}


.react-modal-content_Order .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.react-modal-content_Order .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.react-modal-content_Order .btn:hover {
  opacity: 0.9;
}

.my-profile-edit {
  justify-content: center;
  display: flex;
  gap: 22px;
  align-items: center;

  @include mobile {
    display: none;
  }
}

.my-profile-editt {
  @include xss-mobile {
    width: 45%;
  }
}

.my-profile-editt h3 {
  padding-top: 16px;
}

.my-profile-edit h3 {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.822);
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  background-color: gray;
  border: none;

  @include mobile {
    font-size: 10px;
    padding: 8px;
  }
}

// **********Edit my Profile**********
.profileEditMain {
  padding: 20px;
}

.profileEditMain .input-roww {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profileEditMain .input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.profileEditMain .input-group label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.profileEditMain .input-group input,
.profileEditMain .input-group select {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.profileEditMain .input-group span {
  padding: 8px 12px;
  display: inline-block;
  font-size: 14px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.profileEditMain .myProfileeditmodal .ant-btn {
  background-color: #4caf50;
  color: white;
}

.profileEditMain .myProfileeditmodal .ant-btn:hover {
  background-color: #45a049;
}

.profileEditMain .myProfileeditmodal .ant-btn:disabled {
  background-color: #f5f5f5;
  color: #bfbfbf;
}

// .myProfilePincode {
//   width: 200px;
//   @include mobile{
//     width:145px;
//   }

// }

.pending-messageAffiliateDashboard {
  font-size: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

// *********************************************************************************
.section2bookInputWrap {
  position: absolute;
  bottom: 41px;
  left: 118px;

  @include desktop {
    bottom: 53px;
  }

  @include tablet-land {
    bottom: 65px;
  }

  @include mobile {
    bottom: 100px;
    left: 67px;
  }

  @include xssm-mobile {
    bottom: 88px;
    left: 54px;
  }

  @include xss-mobile {
    bottom: 83px;
    left: 66px;
  }

}

.section2bookInputWrap h2 {
  color: white;

  @include xss-mobile {
    font-size: larger;
  }
}

.section2bookInputWrap p {
  width: 84%;
  color: #AAAAAA;
  font-size: 19px;

  @include xss-mobile {
    width: 94%;
    font-size: 9px;
  }

  @include xssm-mobile {
    width: 96%;
    font-size: 10px;
  }

  @include mobile {
    width: 91%;
    font-size: 10px;
  }
}

.section2bookInputWrap-price .discounted-pricee {
  font-size: 18px;
  color: white;
}

.section2bookInputWrap-price .discounted-price {
  font-size: 18px;
  color: #AAAAAA;
  padding-left: 7px;
}

.section2bookInputWrap-commonbtn {
  position: absolute;
  bottom: 45px;
  right: 61px;

  @include mobile {
    bottom: 39px;
    right: 106px;
  }

  @include xssm-mobile {
    bottom: 34px;
    right: 83px;
  }

  @include xss-mobile {
    bottom: 31px;
    left: 83px;
  }
}

.myaccountcitynoedit {
  width: 200px;

  @include mobile {
    width: 145px;
  }

  @include xssm-mobile {
    width: 130px;
  }

  @include xss-mobile {
    width: 100px;
  }
}

//**************my Account mobile verison******************
.myAccountmobileversion {
  display: none;

  @include mobile {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 10px;
    gap: 48px;
  }

  @include xssm-mobile {
    display: flex;
    gap: 10px;
  }
}

// *******************New Registration for Client*******************************************8
.newregistrationClientMain {
  display: flex;
  justify-content: space-between;
  @include mobile{
    display: block;
  }
}

.registrationLeftBox {
  background-image: url("../images//registrationLeftBox.png");
  height:410px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.registrationLeftBox img {
  width: 100%;
  height: 100%;
}

.registrationLeftRight h1 {
  padding: 5px 0px 0px 16px;
}

.registrationLeftRight-inner {
  padding: 5px 0px 0px 16px;
}

.newregistartion-form {
  width: 100%;
  color:black;
  border: 1px solid #80808036;
  font-size: 16px;
  border-radius: 6px;
  outline: none;
  line-height: normal;
  margin: 10px;
  padding: 8px;
  @include mobile{
    width:90%;
  }

}

.newAgreeterm-policy {
  padding: 10px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.473);
}
.new-paymentaccepted{
  padding: 10px;
  font-weight:700;
  color: rgba(0, 0, 0, 0.705);
  font-size:19px;
}
.payment-methods-icon-image{
  width:550px;
  @include tablet-land{
    width:360px;
  }
  @include mobile{
    width:340px;
  }
  @include xss-mobile{
    width:272px;
  }
}

.CountDown2product{
  text-align: center;

}
.newProduct-bannner-head{
  font-size: 4.5rem;
  font-weight:700;
  color: black;
}
.newProduct-bannner-para{
  font-size: 3.5rem;
  font-weight: 700;
  color: black;
}
.orderSummaryproduct2main{
  margin-top: 16px;
  border: 1px solid rgba(128, 128, 128, 0.678);
  padding: 10px;
  border-radius: 5px;
}
.orderSummaryproduct2mainChild{
  display: flex;
  align-items:center;
  gap:40px;
}
.discountCodeproduct2input{
  display: flex;
  align-items: center;
  gap:20px;
  background-color: white;
 
}
.discountCodeproduct2verifybtn{
  color: #2d4cc3;
  font-size:20px;
  font-weight: 800;
  background-color: white;
}
// ****single page modal
.eachusersingleproduct-order{
  width:70% !important;
}
