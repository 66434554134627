@import "../../assets/scss/media";
@import "../../assets/scss/mixin";

.modal-dialog-centered {
    @include x-767-tablet {
        margin: 0;
    }
}

.modal-dialog {
    @include md-tablet {
        max-width: 98%;
    }

    @include x-767-tablet {
        max-width: 100%;
    }
}

.modal-content {
    .btn-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 1.6rem;
        color: #000;
        opacity: 1;
        z-index: 1;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.product-details {
   margin-bottom: 4rem;
    position: relative;

    .section {
        display: flex;
        flex-wrap: wrap;

        &.step1 {
            padding: 1rem 0;

            .checkoutDataWrap {
                display: none;
            }
        }

        &.step2 {
            padding: 3rem 0 0;
        }

        &.step3 {
            padding: 14rem 0rem 1rem 0rem;
            flex-direction: column;
            text-align: center;

            .commBtnWrap {
                button {
                    margin-bottom: 2rem;
                    // span {
                    //   transform: rotate(180deg);
                    // }
                }
            }
        }
    }

    .padLeft {
        padding: 0 1rem;
        max-height: 55rem;
        // overflow: auto;
        @include scrollBar;

        @include md-tablet {
            padding-bottom: 10rem;
        }

        @include x-767-tablet {
            max-height: 100%;
            overflow: unset;
            padding-bottom: 0;
        }
    }

    .product-image-slider {
        border-radius: 10px;
        background-color: #D2EBFF;
        padding-bottom:21px;
        // height: 400px;

        @include x-767-tablet {
            padding-bottom: 5rem;
        }

        .main-image {
            // max-width: calc(100% - 2rem);
            // height: 43.2rem;
            display: flex;
            border-radius: 1rem;
            justify-content: center;
            overflow: hidden;
            align-items: center;
            border-radius: 1rem;
            overflow: hidden;

            @include md-tablet {
                height: 30.2rem;
            }

            @include x-767-tablet {
                max-width: calc(100% - 0rem);
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 65px 42px 42px 42px;
            }
        }

        .thumbs-container {
            // margin-top: 2rem;
            .swiper {
                .swiper-wrapper {
                    .swiper-slide {
                        width: 8.3rem;
                        height: 7.6em;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .product-info {
        margin-bottom: 2rem;

        h1 {
            font-size: 2.6rem;
        }

        p {
            font-size: 1.6rem;
        }
    }

    .bundle-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        label {
            font-size: 1.8rem;
            font-family: "AeonikTRIAL-Bold";
            color: #1d1d1f;

            @include mobile {
                padding-left: 4px;
            }
        }

        select {
            height: 3.5rem;
            width: 60%;
            border: 0.2rem solid #919191;
            border-radius: 5rem;
            padding: 0 1.5rem;
            font-size: 1.4rem;
            color: #1d1d1f;
            font-family: "AeonikTRIAL-Regular";
            background-image: linear-gradient(45deg, transparent 50%, #1d1d1f 60%),
                linear-gradient(135deg, #1d1d1f 40%, transparent 50%) !important;
            background-position: calc(100% - 22px) 14px, calc(100% - 15px) 14px,
                100% 0;
            background-size: 7px 7px, 7px 7px;
            background-repeat: no-repeat;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;

            @include mobile {
                width: 100%;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .subcourses {
        margin-bottom: 2rem;

        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            column-gap: 2.4rem;
            row-gap: 1.5rem;

            li {
                font-size: 1.6rem;
                color: #5d5d5d;

                &::before {
                    content: url(../../assets/images/bookIcon.svg);
                    padding-right: 0.5rem;
                }
            }
        }
    }

    .colorQtWraps {
        display: flex;
        gap: 4rem;

        .color-selector {
            .color-select {
                font-size: 1.6rem;
                font-family: "AeonikTRIAL-Bold";
                color: #1d1d1f;
            }

            .radioWrap {
                display: flex;
                align-items: center;
                gap: 2rem;
                margin-top: 1rem;

                input[type="radio"] {
                    &:checked::before {
                        content: "";
                        display: block;
                        width: 1.9rem;
                        height: 1.9rem;
                        border-radius: 50%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                label {
                    font-size: 1.6rem;
                    font-family: "AeonikTRIAL-Regular";
                    color: #919191;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    cursor: pointer;
                }
            }
        }

        .qtSelectWrap {
            display: flex;
            flex-direction: column;

            label {
                font-size: 1.6rem;
                font-family: "AeonikTRIAL-Bold";
                color: #1d1d1f;
            }

            label:checked {
                font-weight: bold;
            }

            .qttBox {
                height: 2.8rem;
                width: 5rem;
                border: 0.2rem solid #919191;
                border-radius: 5rem;
                padding: 0 1.5rem;
                font-size: 1.8rem;
                font-family: "AeonikTRIAL-Regular";
                color: #1d1d1f;
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 0;
            }

            p {
                font-size: 1.6rem;
                font-family: "AeonikTRIAL-Bold";
                color: #545454;
                margin-top: 5px;
            }
        }
    }

    .produtcMainPrices {
        margin-top: 3rem;

        h2 {
            font-size: 4rem;
            font-family: "AeonikTRIAL-Bold";
            color: #2d4cc3;
            margin-bottom: 0;

            del {
                font-size: 3.2rem;
                font-family: "AeonikTRIAL-Regular";
                color: #919191;
            }
        }
    }

    .defaultBox {
        margin-top: 3rem;

        label {
            font-size: 1.8rem;
            font-family: "AeonikTRIAL-Bold";
            color: #1d1d1f;
        }

        p {
            font-size: 1.6rem;
        }

        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            column-gap: 2.4rem;
            row-gap: 1.5rem;

            li {
                font-size: 1.6rem;
                color: #5d5d5d;

                &::before {
                    content: url(../../assets/images/bookIcon.svg);
                    padding-right: 0.5rem;
                }
            }
        }
    }

    .order-summary {
        background-color: #fff;
        box-shadow: 0 0 1rem 0 #bcbcbcbd;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        height: 9.3rem;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5rem;

        @include mobile {
            border: 1px solid gray;
            border-radius: 1rem;
            padding: 18px;
        }

        @include x-767-tablet {
            flex-direction: column;
            align-items: flex-start;
            box-shadow: unset;
            position: relative;
            height: 100%;
            gap: 1rem;
        }

        .leftBoxWp {
            display: flex;
            align-items: center;
            gap: 1rem;

            @include mobile {
                align-items: center;
            }

            @include x-767-tablet {
                width: 100%;
                justify-content: flex-start;
            }

            .finalProductImgWRap {
                height: 7rem;
                width: 7rem;
                overflow: hidden;
                padding: 0.5rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include mobile {
                        object-fit: contain;
                    }
                }
            }

            .nameColrWrap {
                h2 {
                    font-size: 1.8rem;
                }

                p {
                    font-size: 1.8rem;
                    font-family: "AeonikTRIAL-Regular";
                    color: #1d1d1f;
                    text-transform: capitalize;

                    span {
                        color: #919191;
                    }
                }
            }
        }

        .midBoxWp {
            h2 {
                font-size: 1.8rem;
            }

            p {
                font-size: 1.8rem;
                font-family: "AeonikTRIAL-Regular";
                color: #919191;
                text-transform: capitalize;
            }
        }

        .rightBoxWp {
            display: flex;
            align-items: center;
            gap: 2rem;

            @include x-767-tablet {
                width: 100%;
                justify-content: space-between;
            }

            .pricing {
                font-size: 3rem;
                font-family: "AeonikTRIAL-Bold";
                color: #1d1d1f;
                margin-bottom: 0;
            }

            .commBtnWrap {
                button {
                    margin-top: 0;
                    padding: 10px 5px 10px 15px;
                }
            }
        }
    }

    .form-container {
        background-color: #e2e2ff;
        padding: 2rem;
        border-radius: 0.8rem;

        h2 {
            text-align: center;
            font-size: 3rem;
            margin-bottom: 3rem;
        }

        .form-group {
            margin-bottom: 2rem;

            .form-label {
                font-size: 1.6rem;
                font-family: "AeonikTRIAL-Bold";
                color: #1d1d1f;
                display: block;
                margin-bottom: 0.8rem;
            }

            .form-control {
                height: 4.5rem;
                padding: 0 1.5rem;
                font-size: 1.6rem;
                color: #181818;
                border: 0;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #919191;
                }
            }

            textarea {
                padding-top: 1rem !important;
                height: 6rem !important;
            }

            .error {
                font-size: 1.6rem;
                color: red;
            }
        }

        .commBtnWrap {
            margin-top: 3rem;

            button {
                width: auto;
                margin: 0 auto;

                // justify-content: flex-end;
                @include md-tablet {
                    // width: 50%;
                }

                @include x-767-tablet {
                    width: 61%;
                }

                span {
                    transform: none !important;
                }
            }
        }
    }

    .payment-methods img {
        width: 100%;
    }
}

.current-price {
    font-weight: bold;
    color: green;
}

.discounted-price {
    text-decoration: line-through;
    color: red;
}

.order-summary button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.product-image-slider{
    position: relative;
}

.main-image {
    position: relative;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2d4cc3;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;

    i {
        font-size: 1.8rem;
        color: #fff;
    }
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.thumbs-container {
    display: flex;
    align-items: center;
}

.prev-thumb-button,
.next-thumb-button {
    background-color: #2d4cc3;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;

    i {
        font-size: 1.2rem;
        color: #fff;
    }
}

.thumbs-swiper {
    flex-grow: 1;
}

.thumbs-swiper .swiper-slide {
    cursor: pointer;
    opacity: 0.5;
}

.thumbs-swiper .swiper-slide.active {
    opacity: 1;
}

.Toastify__toast-container {
    font-size: 1.6rem;
}

.order_affiliate {
    height: 3.5rem;
    width:79%;
    border: 0.2rem solid #919191;
    border-radius: 5rem;
    padding: 0 1.5rem;
    font-size: 1.4rem;
    color: #1d1d1f;
    outline: none;
    font-family: "AeonikTRIAL-Regular";

    @include mobile {
        width: 70%;
    }
}

.paymentsuccessfull {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background: rgba(0, 0, 0, 0.5);
}

.paymentsuccessfull .modal-content {
    // width: 50%;
    // background-color: #fff;
    // padding: 20px;
    // border-radius: 8px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.paymentsuccessfull .modal-content p {
    font-size: 18px;
    color: #333;
    margin: 0;
}
.product-image-slider14XOLED{
    position: absolute;
    top: 20px;
    left: 20px;
    color:#FFF;
    font-weight:700;
    display: flex;
    justify-content: center;
    width:200px;
    height: 30px;
    border-radius:15px;
    font-size:14px;
    padding:20px 0px 20px 0px;
    letter-spacing: 0.5px;
    background: rgba(45, 75, 195, 0.89);
    align-items: center;
}
