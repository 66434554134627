@import "../../assets/scss/media";
@import "../../assets/scss/mixin";

#innerPage {
    background: radial-gradient(at 50% 100%,
        #fff 25%,
        #d9e6ff 100%,
        rgba(226, 234, 247, 0.65) 100%);
    width: 100%;
    height: 100%;
    padding: 9% 0 5%;
    @include textGradient;
    @include mobile{
        padding:30% 0 5%; 
    }

    .mainPara {
        width: 75%;
        margin: 0 auto;
        text-align: center;
        @include paraTypo;

        @include x-767-tablet {
            width: 80%;
            font-size: 1.6rem;
        }
    }

    .boxWrpper {
        margin: 5rem 0 0 0;
        padding: 6rem 3rem;
        background-color: #fff;
        border-radius: 0.8rem;
        box-shadow: 0 0 0.4rem 0 #e6e6e6;

        .paraBox {
            margin-bottom: 3rem;

            .contentText {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }

            p {
                font-size: 1.8rem;
            }
        }

        .dataBoxWrap {
            h4 {
                font-size: 2.4rem;
                margin-bottom: 2.6rem;
            }

            ul {
                padding-left: 0;
                margin-bottom: 3rem;

                li {
                    font-size: 1.8rem;
                    margin-bottom: 1.8rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    span {
                        font-size: 1.8rem;
                        font-family: "AeonikTRIAL-Bold";
                        color: #1d1d1f;
                    }

                    a {
                        color: #2d4cc3;
                    }
                }
            }
        }

        .lastPara {
            @include paraTypo;
            margin-bottom: 0;
        }
    }
}