#innerPage {
  background: radial-gradient(at 50% 100%, #fff 25%, #d9e6ff 100%, rgba(226, 234, 247, 0.65) 100%);
  width: 100%;
  height: 100%;
  padding: 9% 0 5%;
}
#innerPage .mainText {
  font-size: 9.5rem;
  text-align: center;
}
@media (max-width: 1366px) {
  #innerPage .mainText {
    font-size: 5.5rem;
  }
}
@media (max-width: 767px) {
  #innerPage .mainText {
    font-size: 3.5rem;
  }
}
#innerPage .mainText .gradientText {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  background: #6d24ba;
  background: linear-gradient(to right, #6d24ba 0%, #96e0f3 25%, #a2b8f7 32%, #a65ff8 49%, #5949e0 77%, #313ed3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "AeonikTRIAL-Bold";
}
@media (max-width: 480px) {
  #innerPage {
    padding: 30% 0 5%;
  }
}
#innerPage .mainPara {
  width: 75%;
  margin: 0 auto;
  text-align: center;
  font-size: 1.8rem;
  text-decoration: none;
  color: #6d6d6e;
}
@media (max-width: 767px) {
  #innerPage .mainPara {
    width: 80%;
    font-size: 1.6rem;
  }
}
#innerPage .boxWrpper {
  margin: 5rem 0 0 0;
  padding: 6rem 3rem;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.4rem 0 #e6e6e6;
}
#innerPage .boxWrpper .paraBox {
  margin-bottom: 3rem;
}
#innerPage .boxWrpper .paraBox .contentText {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}
#innerPage .boxWrpper .paraBox p {
  font-size: 1.8rem;
}
#innerPage .boxWrpper .dataBoxWrap h4 {
  font-size: 2.4rem;
  margin-bottom: 2.6rem;
}
#innerPage .boxWrpper .dataBoxWrap ul {
  padding-left: 0;
  margin-bottom: 3rem;
}
#innerPage .boxWrpper .dataBoxWrap ul li {
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
}
#innerPage .boxWrpper .dataBoxWrap ul li:last-child {
  margin-bottom: 0;
}
#innerPage .boxWrpper .dataBoxWrap ul li span {
  font-size: 1.8rem;
  font-family: "AeonikTRIAL-Bold";
  color: #1d1d1f;
}
#innerPage .boxWrpper .dataBoxWrap ul li a {
  color: #2d4cc3;
}
#innerPage .boxWrpper .lastPara {
  font-size: 1.8rem;
  text-decoration: none;
  color: #6d6d6e;
  margin-bottom: 0;
}/*# sourceMappingURL=layoutStyle.css.map */